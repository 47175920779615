import React, {useRef, useState, useEffect} from "react";
import Navbar from "components/navigation/navbar/Navbar";
import LoadingSpinner from "components/loading/LoadingSpinner";
import {axios_sato_report } from 'pages/taxDashboard/pages/sato/support_functions/axiosRequests'
//import { exportToExcel } from './support_functions/exportToExcel'
import SuccessAlert from "components/alert/SuccessAlert";
import WarningAlert from "components/alert/WarningAlert";
import GenericFooter from "components/navigation/footer/GenericFooter";
export default function SatoReport(){


    const yearRef = useRef()
    const monthRef = useRef()
    const [yearError, setYearError] = useState(false)
    const [monthError, setMonthError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [fiscalYear, setFiscalYear] = useState([])
    const [fiscalPeriod, setFiscalPeriod] = useState([])

    
    const handleYear = () =>{
        setYearError(false)
        setSuccessMessage(false)
    }

    const handleMonth = () =>{
        setMonthError(false)
        setSuccessMessage(false)
    }

    const handleSubmit = async () =>{
        let error = false
        setLoading(false)
        setSuccessMessage(false)

        if (!fiscalYear.includes(yearRef.current.value)){
            setYearError(true)
            error = true
        }

        if (!fiscalPeriod.includes(monthRef.current.value)){
            setMonthError(true)
            error = true
        }

        if (error === false){
            setLoading(true)

            axios_sato_report(yearRef.current.value, monthRef.current.value).then( data =>{

                if (data.error){
                    setErrorMessage(true)
                    setLoading(false)
                    return
                }

                //exportToExcel(data.data, yearRef.current.value, monthRef.current.value)
                window.open(data.data.url, "_blank")
                setLoading(false)
                setSuccessMessage(true)
                monthRef.current.value = 'Monat'
                yearRef.current.value = 'Gjahr'
                return
            })

        }else{
            return null
            
        }
    }


    useEffect(() =>{

        let date = new Date()

        if (date.getMonth() >= 9){
            setFiscalYear([(date.getFullYear() + 1 ).toString(), date.getFullYear().toString(), (date.getFullYear() - 1).toString()])
        }else{
            setFiscalYear([date.getFullYear().toString(), (date.getFullYear() - 1).toString()])
        }

        setFiscalPeriod(['1','2','3','4','5','6','7','8','9','10','11','12'])

    },[])




    return (
        <div>

            <div>
                <Navbar activePage="/tax_dashboard"/>
            </div>


            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md mt-16">
                    <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">SATO Reconciliation Report</h1>
                </div>
                
                {successMessage ?<div className="w-1/4 m-auto"><SuccessAlert title={'Export complete'} /></div>: <></>}

                {errorMessage ?<div className="w-1/4 m-auto"><WarningAlert title={'There was an error'} subtitle={'Contact Bernd or Kevin'} /></div>: <></>}

                <div className="mt-8 sm:mx-auto shadow sm:w-full sm:max-w-md">
                    <div className="flex justify-center">
                        <div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10">
                            <div className="">
                                <label htmlFor="fy" className={`${yearError ? 'text-red-600 animate-bounce' : 'bg-white text-gray-700'} font-medium block text-lg font-mediumborder-b-2`}>
                                    Fiscal Year
                                </label>
                                <select
                                    id="fy"
                                    name="fy"
                                    className={` text-lg mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md`}
                                    defaultValue='Gjahr'
                                    ref={yearRef}
                                    onChange={handleYear}
                                >
                                    <option className="text-lg" disabled>Gjahr</option>
                                    {fiscalYear.map(i =>{
                                    return <option key={i} className="text-lg">{i}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10">
                            <div className="">
                                <label htmlFor="fp" className={`${monthError ? 'text-red-600 animate-bounce' : 'bg-white text-gray-700'} block text-lg font-medium border-b-2"`} >
                                    Fiscal Period
                                </label>
                                <select
                                    id="fp"
                                    name="fp"
                                    className="text-lg mt-1 block w-full pl-3 pr-10 py-2  border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    defaultValue='Monat'
                                    ref={monthRef}
                                    onClick={handleMonth}
                                >
                                    <option className="text-lg" disabled>Monat</option>
                                    {fiscalPeriod.map(i =>{
                                    return <option key={i} className="text-lg">{i}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                    </div>

                    <div className="mb-12 flex justify-center m-auto">
                    {loading ? 
                                            <button
                            type="button"
                            className="px-4 py-2 border cursor-progress border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200"
                            disabled
                        >
                            Please Wait
                        </button>
                    
                    :
                        <button
                            type="button"
                            className="px-4 py-2 border cursor-pointer border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={handleSubmit}
                        >
                            Generate report
                        </button>

                    }
                    </div>
                </div>

            </div>

            <div>
                {loading ? 
                    <LoadingSpinner />

                    :
                    <></>
                }
            </div>

            <div>
                <GenericFooter />
            </div>
      </div>
    )
}


