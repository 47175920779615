import axios from "axios";
import { sapDashboardAPIBaseuri } from "globals/variables/axiosVariables";



export async function AxiosCMDCrossChecks(data) {

    let postData = {
        data : data
    }

    try{
        const response = await axios({
            method: 'POST',
            url: sapDashboardAPIBaseuri + 'md_dashboard/mass_checks/cmd_cross_check/',
            headers: { 'content-type': 'application/json' },
            data : postData,
            withCredentials: true,
        });
        
        if (response.status === 200 && response.data){
            return {error : false, data : response.data}
        }else{
            return {error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}

export async function AxiosECCrossChecks(data) {

    let postData = {
        data : data
    }

    try{
        const response = await axios({
            method: 'POST',
            url: sapDashboardAPIBaseuri + 'md_dashboard/mass_checks/ecc_cross_check/',
            headers: { 'content-type': 'application/json' },
            data : postData,
            withCredentials: true,
        });
        
        if (response.status === 200 && response.data){
            return {error : false, data : response.data}
        }else{
            return {error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}


export async function AxiosARIBAcrossChecks(data) {

    let postData = {
        data : data
    }

    try{
        const response = await axios({
            method: 'POST',
            url: sapDashboardAPIBaseuri + 'md_dashboard/mass_checks/ariba_cross_check/',
            headers: { 'content-type': 'application/json' },
            data : postData,
            withCredentials: true,
        });
        
        if (response.status === 200 && response.data){
            return {error : false, data : response.data}
        }else{
            return {error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}


export const Axios_mass_check_poll =async (uuid) =>{
    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'md_dashboard/mass_check/poll/',
            headers: { 'content-type': 'application/json' },
            params: {processUUID : uuid},
            withCredentials: true,
        });
        
        if (response.status === 200 ){
            return {data: response.data, error : false}
            
        }else{
            return {data: response.data, error : false}
        }

    }catch(e){
        return {data: [], error : true}
    }
}