import React, {useState, useEffect} from "react";
import Navbar from "components/navigation/navbar/Navbar";
import LoadingSpinner from "components/loading/LoadingSpinner";
import SuccessAlert from "components/alert/SuccessAlert";
import WarningAlert from "components/alert/WarningAlert";
import GenerticFooter from 'components/navigation/footer/GenericFooter'
import UserMenu from "./components/UserMenu";



export default function GlobalOpenItems() {
    
    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [fileUrl, setFileURL] = useState('')




    useEffect(()=>{
        if (fileUrl){
            window.open(fileUrl, "_blank")
            setSuccessMessage(true)
        }
    },[fileUrl])

  return (
    <div>

        <div>
            <Navbar activePage="/ap_dashboard"/>
        </div>

        <div className="flex flex-col justify-center py-8 sm:px-6 lg:px-8 ">

            <div className="sm:mx-auto sm:w-full sm:max-w-md mt-4">
                <h1 className="mt-4 text-center text-3xl font-extrabold text-gray-900">ARE Open Item Report</h1>
            </div>

            {successMessage ?<div className="w-1/4 m-auto"><SuccessAlert title={'Export complete'} /></div>: <></>}
            {errorMessage ?<div className="w-1/4 m-auto"><WarningAlert title={'There was an error'} subtitle={'Contact Bernd or Kevin'} /></div>: <></>}


            <div>
                <UserMenu setFileURL={setFileURL} loading={loading} setLoading={setLoading} setErrorMessage={setErrorMessage}
                setSuccessMessage={setSuccessMessage } 
                />
            </div>


        </div>



        <div>
            {loading ? <LoadingSpinner />:<></>}
        </div>


        <div>
            <GenerticFooter />
        </div>

    </div>
  )
}
