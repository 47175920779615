import React,{useEffect, useState} from 'react'
import { ArrowCircleUpIcon } from '@heroicons/react/outline'
import {read, utils} from 'xlsx';

function UploadSearchFile(props) {
    const [displayReportText, setDisplayReportText] = useState("")
   

    const uploadExcelData = async (e) =>{
        const file = e.target.files[0]
        const data = await file.arrayBuffer();
        const wb = read(data);
        const ws = wb.Sheets[wb.SheetNames[0]]
        let jsonSheet = utils.sheet_to_json(ws)
        
        if (jsonSheet.length > 0){
            for(let i = 0; i < jsonSheet.length; i++){
                jsonSheet[i] = convertObjectValuesToString(jsonSheet[i])
            }
            props.setUploadedData(jsonSheet)
          }


    }
    
    function convertObjectValuesToString(obj) {
        for (var key in obj) {
          if (typeof obj[key] !== 'string') {
            obj[key] = String(obj[key]);
          }
        }
        return obj;
      }

useEffect(()=>{

    switch (props.selectedReportText){
        case "CMD_CHECK":
            setDisplayReportText("CMD Cross Check")
            break;
        case "ECC_CHECK":
            setDisplayReportText("ECC Cross Check")
            break;
        case "ARIBA_CHECK":
            setDisplayReportText("ARIBA Cross Check")
            break;
    }

},[props.selectedReportText])

  return (
    <div className="bg-grey-lighter mt-4 flex justify-center">
    <label className="lg:w-64 md:w-24 flex flex-col items-center px-6 py-6 bg-white text-blue-500 rounded-lg shadow-lg tracking-wide uppercase border border-blue-500 cursor-pointer hover:bg-blue-300 hover:text-white">
    <ArrowCircleUpIcon className='h-8 w-8' />
        <span className="mt-2 text-base leading-normal text-center">Upload {displayReportText} Component File</span>
        <input onChange={uploadExcelData} type='file' className="hidden" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
    </label>
</div>
  )
}

export default UploadSearchFile