import React, { useState, useEffect } from "react";
import Navbar from "components/navigation/navbar/Navbar";
import LoadingSpinner from "components/loading/LoadingSpinner";
import { axios_vendor_customer_saerch, downloadJsonContents } from 'pages/mdDashboard/pages/vendorCustomerSearch/support_functions/axiosRequest'
import SuccessAlert from "components/alert/SuccessAlert";
import WarningAlert from "components/alert/WarningAlert";
import VendorCustomerSearchDropdown from 'pages/mdDashboard/pages/vendorCustomerSearch/components/vendorCustomerSearchDropdown'
import ReportTable from 'components/reportTable/reportTable'
import CustomerDropdown from 'pages/mdDashboard/pages/vendorCustomerSearch/components/customerDropdown'
import VendorDropdown from 'pages/mdDashboard/pages/vendorCustomerSearch/components/vendorDropdown'
import InputField from 'pages/mdDashboard/pages/vendorCustomerSearch/components/inputField'
import { useLocation } from "react-router-dom";
import GenericFooter from "components/navigation/footer/GenericFooter";


export default function VendorCustomerSearch() {

    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [warningMessage, setWarningMessage] = useState('')
    const [showWarningMessage, setShowWarningMessage] = useState(false)
    const [selectedReportName, setSelectedReportName] = useState('')
    const [searchField, setSearchField] = useState('')
    const [searchInput, setSearchInput] = useState('')
    const [showDataTable, setShowDataTable] = useState(false)
    const [tableData, setTableData] = useState([])
    const [defaultColumnHeaders, setDefaultColumnHeaders] = useState(null)
    const [hqOrGlobal, setHqOrGlobal] = useState(false)
    const [apOrMd, setApOrMd] = useState('')
    const location = useLocation()
    const [fileUrl, setFileUrl] = useState("")
    const [jsonUrl,setJsonUrl] = useState("")



    //export the data to excel 
    const exportDataToExcel = () =>{
        window.open(fileUrl, "_blank")
    }

    console.log(searchField)

    //handle the submit button
    const handleSubmit = async () => {
        let error = false
        setLoading(false)
        setSuccessMessage(false)
        setShowDataTable(false)

        if (selectedReportName === '' || searchField === '' || searchInput === '') {
            error = true
            setWarningMessage('You forgot to select something')
            setShowWarningMessage(true)
        } else {
            setShowWarningMessage(false)
        }
        

        //set the column headers depending on the report
        let temp;
        switch (selectedReportName) {
            
            case 'customer_search':
                 temp = ['SAP System', 'ARE', 'Company Code' ,'Mandt', 'Customer Number', 'Name1', 'Name2', 'Name3', 'Name4', 'Street', 'Postal Code', 'City', 'Country', 'Region', 'IFA Number', 'Vat Number',
                    'LOEVM', 'SPERR', 'AUFSD', 'NODEL', 'FAKSD', 'Key']
                setDefaultColumnHeaders(temp)
                break;

            case 'vendor_search':
                temp = ['SAP System', 'Mandt', 'Company Code', 'Vendor Number', 'Name1', 'Name2', 'Name3', 'Name4', 'Street', 'Postal Code', 'City', 'Country', 'Region', 'IFA Number',
                    'STCD1', 'STCD2', 'Vat Number', 'STCEG', 'LOEVM', 'SPERR', 'SPERM', 'BANKS', 'BANKL', 'Bank Account', 'IBAN', 'Key']
                setDefaultColumnHeaders(temp)
                break;

            default:
                setDefaultColumnHeaders(null)
        }


        if (error === false) {
            setLoading(true)
                axios_vendor_customer_saerch(selectedReportName, searchField, searchInput, hqOrGlobal).then(data => {

                    if (data.error){
                        setErrorMessage(true)
                        setLoading(false)
                        return
                    }
                    setFileUrl(data.data.fileUrl)
                    setJsonUrl(data.data.jsonUrl)    
                })
        } else {
            return null

        }
    }

    // wait until the table data is updated in use state before shwoing table
    useEffect(() => {
        if (tableData) {
            if (tableData.length !== 0){
                setLoading(false)
                setShowDataTable(true)
                return
            }
        }
    }, [tableData])


    // when the jsonURl value is updated go get the data
    useEffect(()=>{
        if (jsonUrl){
            downloadJsonContents(jsonUrl).then(data =>{
                setTableData(data)
            })
        }
    },[jsonUrl])


    //when a new report is selected then reset the vendor customer selection
    useEffect(() => {
        setSearchField('')
        setSearchInput('')
    }, [selectedReportName])
    

    //set the page name when the page loads to see if its an HQ search or vendor search

    useEffect(() => {
        switch (location.pathname) {
            case '/md_dashboard/vendor_customer_search_hq':
                setHqOrGlobal(false)
                setApOrMd('md')
                break
            case '/ap_dashboard/vendor_customer_search_hq':
                setHqOrGlobal(false)
                setApOrMd('ap')
                break
            case '/md_dashboard/vendor_customer_search_global':
                setHqOrGlobal(true)
                setApOrMd('md')
                break
            default:
                break
        }

    }, [location])



    return (
        <div>

            <div>
                <Navbar activePage={apOrMd === 'ap' ? "/ap_dashboard" : "/md_dashboard"} />
            </div>


            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md mt-16">
                    <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Vendor Customer Search</h1>
                    <h3 className="mt-1 text-center text-2xl font-semibold text-gray-900">

                        {hqOrGlobal === 'hq' ?
                            'HQ systems'
                            :
                            ''
                        }

                        {hqOrGlobal === 'global' ?
                            'Global Search'
                            :
                            ''
                        }


                    </h3>
                </div>

                {successMessage ? <div className="w-1/4 m-auto"><SuccessAlert title={'Export complete'} /></div> : <></>}
                {errorMessage ? <div className="w-1/4 m-auto"><WarningAlert title={'There was an error'} subtitle={'Contact Bernd or Kevin'} /></div> : <></>}
                {showWarningMessage ? <div className="w-1/4 m-auto"><WarningAlert title={warningMessage} subtitle={''} /></div> : <></>}




                <div className="mt-8 sm:mx-auto shadow sm:w-full sm:max-w-md">

                    <div className="flex justify-center mt-4 mb-6">
                        <VendorCustomerSearchDropdown setSelectedReportName={setSelectedReportName} />
                    </div>

                    <div className="flex justify-center mt-4 mb-6">
                        {selectedReportName === 'customer_search' ?
                            <CustomerDropdown setSearchField={setSearchField} />
                            :
                            <></>
                        }

                        {selectedReportName === 'vendor_search' ?
                            <VendorDropdown setSearchField={setSearchField} />
                            :
                            <></>
                        }
                    </div>

                    {searchField !== '' && selectedReportName !== '' ?
                        <div className="flex justify-center mt-4 mb-6">
                            <InputField setSearchInput={setSearchInput} />
                        </div>
                        :
                        <></>
                    }




                    <div className="mb-12 flex justify-center m-auto">
                        {loading ?
                            <button
                                type="button"
                                className="px-4 py-2 border cursor-pointer border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200"
                                disabled
                            >
                                Please Wait
                            </button>

                            :
                            <button
                                type="button"
                                className="px-4 py-2 border cursor-pointer border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={handleSubmit}
                            >
                                Search
                            </button>

                        }
                    </div>
                </div>

            </div>

            <div>
                {loading ?
                    <LoadingSpinner />

                    :
                    <></>
                }
            </div>


            {showDataTable ?
                <div className="w-2/3 m-auto mb-24">
                    <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={exportDataToExcel}
                    > Export to excel</button>



                    <ReportTable data={tableData} columnHeaders={defaultColumnHeaders} />
                </div>

                :
                <></>
            }

            <div>
                <GenericFooter />
            </div>
        </div>
    )
}


