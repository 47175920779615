import React, {useState, useEffect} from 'react'


export default function SingleSelectDropdown(props) {

    const [userSelection, setUserSelection] = useState([])


    const updateSelected = (e) =>{
        if (e.target.value === ''){
            props.setState([])
        }else{
            props.setState([e.target.value])
        }
    }

    useEffect(() =>{
        setUserSelection(props.dropDownSelections)
    },[props.dropDownSelections])

    useEffect(() =>{
        props.setState([props.defaultField])
    },[props.defaultField])


  
    return (
      <div className='mb-6'>
  
  
        <select  onChange={updateSelected} className='border border-gray-200/75 rounded-md min-h-12 px-4 py-2 w-full'>

            {userSelection.map( i =>{

                if(i[0] === props.defaultField){
                    return (<option key={i[0]} className='rounded-md' selected value={i[0]}>{i[1]}</option>)
                }else{
                    return (<option key={i[0]} className='rounded-md' value={i[0]}>{i[1]}</option>)
                }
            }
            )}
            

        </select>
    
        <div className='text opacity-75 text-left ml-4'>
            {props.subTitle}
        </div>


      </div>
    )
}

