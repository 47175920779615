import React, { useEffect, useState } from 'react'
import Multiselect from 'multiselect-react-dropdown';

export default function MultiSelectDropdown(props) {

  const [displayOptions, setDisplayOptions] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  const [showPlaceholder, setShowPlaceholder] = useState(false)


  const onSelect = (selectedList) =>{
    let temp_keys = []

    for (let i = 0; i< props.dropDownSelections.length; i++){

      if (selectedList.includes(props.dropDownSelections[i][1])){
        temp_keys.push(props.dropDownSelections[i][0])
      }
    }

    setSelectedKeys(temp_keys)
    props.setState(temp_keys)
  }


  
  useEffect(()=>{
    var result = props.dropDownSelections.map(function(a) {return a[1]});
    setDisplayOptions(result)
  },[props.dropDownSelections])


  useEffect(()=>{
    if (selectedKeys.length>0){
      setShowPlaceholder(false)
    }else{
      setShowPlaceholder(true)
    }
  },[selectedKeys])



  return (
    <div className='mb-6'>


          <Multiselect
            onSelect={onSelect}
            onRemove={onSelect}
            isObject={false}
            onKeyPressFn={function noRefCheck(){}}
            onSearch={function noRefCheck(){}}
            options={displayOptions}
            placeholder={showPlaceholder ? props.defaultText : null}

            />


      <div className='text opacity-75 text-left ml-4'>
          {props.subTitle}
      </div>
    </div>
  )
}