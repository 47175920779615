
import React, { useState, useEffect } from "react"
import ReportTablePagination from 'components/reportTable/pagination/reportTablePagination'

  
  export default function ReportTable(props) {

    const [currentPage, setCurrentPage] = useState(0)

    
    const [columnHeaders, setColumnHeaders] = useState(['No data'])
    const [shownColumnHeader, setShownColumnHeaders] = useState([])

    const [tableShownData, setTableShownData] = useState([])
    const [tableCompleteData, setTableCompleteData] = useState([])

    const [totalRecords, setTotalRecords] = useState(0)







    //create the column headers or take the first row keys as column headers
    useEffect(() =>{
      
      if (props.data === undefined){
        return
      }else if (props.data.length === 0){
        return
      }


      
      if (props.data.length > 0){

        setTableCompleteData(props.data)
        setTotalRecords(props.data.length)
        setTableShownData(props.data.slice(0,10))

        
      }

    
      
      if (props.columnHeaders === undefined || props.columnHeaders === null){
        setShownColumnHeaders(Object.keys(props.data[0]))
        setColumnHeaders(Object.keys(props.data[0]))
      }else{
        setShownColumnHeaders(props.columnHeaders)
        setColumnHeaders(Object.keys(props.data[0]))
      }

 
      setCurrentPage(1)
      
    },[props.data])

    



    //change the displayed page when user pushes forward or backward 

    useEffect(() =>{
      setTableShownData(tableCompleteData.slice((currentPage - 1) * 10, ((currentPage - 1) * 10) + 10))
    },[currentPage])

    



    return (
      <div>

        <div className="mt-2 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300 table-auto">
                  <thead className="bg-gray-50">
                    <tr key={8675309}>
                      {shownColumnHeader.map((i, index) =>{
                      return (
                        <th key={index} scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 capitalize">
                          {i}
                        </th>
                      )

                      })}
                    </tr>
                  </thead>


                    <tbody className="bg-white">
                      {tableShownData.map((i, index) => {
                        return (
                          <tr key={index} className={index % 2 === 0 ? undefined : 'bg-gray-100'}>

                            {columnHeaders.map((l, lindex) =>{

                              return (
                                <td key={lindex} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">{i[l]}</td>
                              )
                            })}
                          </tr>
                            )
                        }
                      )}
                    </tbody>


                </table>
              </div>
              <ReportTablePagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalRecords={totalRecords} />
            </div>
          </div>
        </div>
      </div>
    )
  }
  