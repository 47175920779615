import React, {useState} from "react";
import Navbar from "components/navigation/navbar/Navbar";
import LoadingSpinner from "components/loading/LoadingSpinner";
import { axios_vendor_differentBank_report } from "./support_functions/axiosVendorDifferentBanks";
import SuccessAlert from "components/alert/SuccessAlert";
import WarningAlert from "components/alert/WarningAlert";
import GenericFooter from "components/navigation/footer/GenericFooter";
import UserSelections from "./components/userSelections";

export default function VendorsWithDifferentBankAccounts(){
    const [showSuccess, setShowSuccess] = useState(false)
    const [showError, setShowError] = useState(false)
    const [showLoading, setShowLoading] = useState(false)
    const [selectedAre , setSelectedAre ] = useState("404Q")
    const [errorMessage, setErrorMessage] = useState()

    const handleSubmit = ()=>{
        setShowError(false)
        setShowSuccess(false)
        setShowLoading(true)

        if (selectedAre === ""){
            setShowError(true)
            setErrorMessage("Please select an ARE")
            return
        }

        axios_vendor_differentBank_report(selectedAre).then(data =>{
            
            if (data.error){
                setErrorMessage("Please contact Kevin")
                setShowLoading(false)
                setShowError(true)
                return
            }

            window.open(data.data.fileUrl, "_blank")
            setShowSuccess(true)
            setShowLoading(false)
            return 
        })
    }
    

    return (
        <div>

            <div>
                <Navbar activePage="/audit_dashboard"/>
            </div>


            <div className="min-h-full flex flex-col justify-center py-4 sm:px-6 lg:px-8 m-4 shadow-md w-1/2 rounded-md mx-auto">
                <div className="sm:mx-auto sm:w-full sm:max-w-md lg:w-2/3 mt-4">
                    <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Vendors with different bank accounts</h1>
                </div>
                
                {showSuccess ?<div className="w-2/3 m-auto"><SuccessAlert title={'Export complete'} /></div>: <></>}

                {showError ?<div className="w-2/3 m-auto"><WarningAlert title={'There was an error'} subtitle={errorMessage} /></div>: <></>}


                <UserSelections setSelectedAre={setSelectedAre} />





                    <div className="mb-12 mt-5 flex justify-center m-auto">
                        {showLoading ? 
                            <button
                            type="button"
                            className="px-4 py-2 border cursor-progress border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200"
                            disabled
                            >
                                Please Wait
                            </button>
                
                        :
                            <button
                                type="button"
                                className="px-4 py-2 border cursor-pointer border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={handleSubmit}
                            >
                                Execute Search
                            </button>

                            }
                    </div>



            </div>

            <div>
                {showLoading ?
                    <LoadingSpinner />

                    :
                    <></>
                }
            </div>


            <div>
                <GenericFooter />
            </div>
      </div>
    )
}


