
import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';

export const axios_send_feedback = async  (message ) =>{
    
    try{
        const response = await axios({
            method: 'POST',
            url: sapDashboardAPIBaseuri + 'feedback/',
            headers: { 'content-type': 'application/json' },
            data : message,
            withCredentials: true,
        });
        
    if (response.status === 200){
            return {error : false}
        }else{
            return {error : true}
        }

    }catch(e){
        console.log(e);
        return {error : true}
    }
}
