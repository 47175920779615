
import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';

export const axios_master_data_reports = async  (reportName ) =>{

    let requestUri

    switch(reportName){
        case "blocked_vendors":
            requestUri = "md_dashboard/master_data_reports/blocked_vendors/"
            break;
        case "venodr_missing_bank_accounts":
            requestUri = "md_dashboard/master_data_reports/vendor_missing_bank_accounts/"
        break;

        case "orbian_report":
            requestUri = "md_dashboard/master_data_reports/orbian_report/"
        break;

        case "payment_term_data":
            requestUri = "md_dashboard/master_data_reports/payment_term_data/"
        break;
    }
    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + requestUri,
            headers: { 'content-type': 'application/json' },
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
      
    }
}


export const downloadJsonContents = async (urlPath) =>{
    const response = await axios({
        method: 'GET',
        url: urlPath,
        headers: { 'content-type': 'application/json' },
        responseType: 'application/json',
        transformResponse: (data) => JSON.parse(data),
    })
    if (response.status === 200){
        return response.data
    }
}
