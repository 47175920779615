import React, {useState, useEffect} from 'react'
import GenericDropdown from './genericDropdown'
import { ArrowCircleUpIcon } from '@heroicons/react/outline'
import ReportTable from 'components/reportTable/reportTable';
import { useSelector } from 'react-redux'
import {read, utils} from 'xlsx';
import { AxiosUploadITComponentScanData } from '../supportFunctions/axiosRequests';
import SuccessAlert from 'components/alert/SuccessAlert';
import WarningAlert from 'components/alert/WarningAlert';
export default function UploadNewComponentList(props) {
 
  const [selectedDropdownSelection,setSelectedDropdownSelection] = useState("")
  const [uploadedData, setUploadedData] = useState([])
  const [loading, setLoading] = useState(false)
  const [showPreviewTable, setShowPreviewTable] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const user = useSelector(state => state.user)


  const uploadExcelData = async (e) =>{
    props.setShowLoading(true)
    const file = e.target.files[0]
    const data = await file.arrayBuffer();
    const wb = read(data);
    const ws = wb.Sheets[wb.SheetNames[0]]
    const defaultColumnNames = [["CONTROL_NUMBER","SAP_SYSTEM","PROGRAM_NAME"]]
    utils.sheet_add_aoa(ws, defaultColumnNames, {origin: "A1"})
    let jsonSheet = utils.sheet_to_json(ws)
    for (let i = 0; i < jsonSheet.length; i++){
      jsonSheet[i]["SAP_SYSTEM"] = selectedDropdownSelection
    }
    
    if (utils.sheet_to_json(ws).length > 0){
      setUploadedData(jsonSheet)
      setShowPreviewTable(true)  
    }

    props.setShowLoading(false)
}


useEffect(()=>{
  setUploadedData([])
  setShowPreviewTable(false)  
},[selectedDropdownSelection])





const uploadData = ()=>{
  setLoading(true)
  setSuccess(false)
  setError(false)
  AxiosUploadITComponentScanData(uploadedData, user.user['gid']).then(data =>{
    
    if (data.error){
      setError(true)
      return 
    }
    
    setSuccess(true)
      
    
  })


  setLoading(false)
}


  return (
    <div className='mt-6 min-w-1/2 flex-col'>
      <div className='flex flex-col mx-auto w-1/6'>
        <div className='mx-auto'>
          <p>Select System</p>
        </div>
        <GenericDropdown setSelectedDropdownSelection={setSelectedDropdownSelection}  dropdownSelections={props.defaultSystems} />
      </div>


      {selectedDropdownSelection ?
        <div className="bg-grey-lighter mt-4 flex justify-center">
            <label className="lg:w-64 md:w-24 flex flex-col items-center px-6 py-6 bg-white text-blue-500 rounded-lg shadow-lg tracking-wide uppercase border border-blue-500 cursor-pointer hover:bg-blue-300 hover:text-white">
            <ArrowCircleUpIcon className='h-8 w-8' />
                <span className="mt-2 text-base leading-normal text-center">Upload {selectedDropdownSelection} Component File</span>
                <input onChange={uploadExcelData} type='file' className="hidden" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
            </label>
        </div>
        :
        <></>
    }

<div className='mt-2 mx-auto w-1/2'>
        {success ?
        <SuccessAlert title={"Upload successful"} />
        :
        <></>
        }

        {error ?
        <WarningAlert title={"Something went wrong, contract Kevin"}  />
        :
        <></>
        }
</div>

{uploadedData.length > 0 && showPreviewTable ?   
    <div className='flex justify-center mt-6 mb-6'>


        {loading ? 
            <button type="button" className="inline-flex cursor-progress items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-medium text-indigo-700  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                   Uploading please wait
            </button>
        :
            <button type="button" onClick={uploadData} className="inline-flex font-semibold items-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-base text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
                    DELETE & REPLACE <br/> 
                    Release Scan List <br/>
                    For System {selectedDropdownSelection}
            </button>
        }
    </div>
    :
    <></>
    }


    

    {showPreviewTable ?
    <div className='mt-6'>
        <ReportTable data={uploadedData} />
    </div>
    :
    <></>
  }
   
    </div>

  )
}




