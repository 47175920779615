import React, {  useEffect, useState } from 'react'
import UserSelection from './components/userSelection'
import Navbar from 'components/navigation/navbar/Navbar'
import SuccessAlert from 'components/alert/SuccessAlert'
import WarningAlert from 'components/alert/WarningAlert'
import LoadingSpinner from 'components/loading/LoadingSpinner'
import GenericFooter from 'components/navigation/footer/GenericFooter'
import UploadSearchFile from './components/uploadSearchFile'
import { AxiosCMDCrossChecks, AxiosECCrossChecks, AxiosARIBAcrossChecks, Axios_mass_check_poll } from './supportFunctions/axiosRequests'

export default function MassChecks() {

    const defaultSelect = "Select upload format"
    const [selectedReportText, setSelectedReportText] = useState('')
    const check_reports = [
        {'reportText':'CMD Cross checks', 'reportName':'CMD_CHECK'},
        {'reportText':'ECC Cross check', 'reportName':'ECC_CHECK'},
        {'reportText':'ARIBA Cross check', 'reportName':'ARIBA_CHECK'}
    ]

    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [uploadedData, setUploadedData] = useState([])
    const [fileUrl, setFileUrl] = useState("")
    const [processUUID, setProcessUUID] = useState("")


        const startPolling = (uuid, pollCounter)=>{
            if (pollCounter === 36){
                setShowErrorMessage(true)
                setLoading(false)
                clearTimeout(startPolling)
                setErrorMessage("Timeout error, contact Kevin or reduce search volume")
                return 
            }
            Axios_mass_check_poll(uuid).then(data =>{
                    if (data.error){
                        setShowErrorMessage(true)
                        setErrorMessage("Error with poller, try again.")
                        setLoading(false)
                        return
                    }
                    if (!data.data.processing){
                        setFileUrl(data.data.fileUrl)
                        clearTimeout(startPolling)
                        return
                    }
                    setTimeout(()=>startPolling(uuid,pollCounter+1), 5000);
                })
        }


    const handleSubmit =() =>{

        setShowErrorMessage(false)
        setSuccessMessage(false)
        setFileUrl("")
        setProcessUUID("")

        if (uploadedData.length === 0){
            setErrorMessage("No rows found in upload file")
            setShowErrorMessage(true)
            return
        }

        if (selectedReportText === defaultSelect){
            setErrorMessage("Select a report")
            setShowErrorMessage(true)
            return
        }


        setLoading(true)

        switch(selectedReportText){
            case "CMD_CHECK":
                AxiosCMDCrossChecks(uploadedData).then(data =>{
                    if (data.error || data.data.error){
                        setErrorMessage(data.data.errorMessage)
                        setShowErrorMessage(true)
                        setLoading(false)
                        return
                    }
                    setProcessUUID(data.data.uuid)
                })
                break;

            case "ECC_CHECK":
                AxiosECCrossChecks(uploadedData).then(data =>{
                    if (data.error || data.data.error){
                        setErrorMessage(data.data.errorMessage)
                        setShowErrorMessage(true)
                        setLoading(false)
                        return
                    }
                    setProcessUUID(data.data.uuid)
                })
                break;

            case "ARIBA_CHECK":
                AxiosARIBAcrossChecks(uploadedData).then(data =>{
                if (data.error || data.data.error){
                    setErrorMessage(data.data.errorMessage)
                    setShowErrorMessage(true)
                    setLoading(false)
                    return
                }
                setProcessUUID(data.data.uuid)
            })
            break;
        }


        
    }


    useEffect(()=>{
        if(processUUID !== ""){
            startPolling(processUUID, 0)
        }
    },[processUUID])

    
    useEffect(()=>{
        if(fileUrl !== ""){
            window.open(fileUrl, "_blank")
            setSuccessMessage(true)
            setLoading(false)
            setUploadedData([])
        }
    },[fileUrl])
    

    return (
    <div>

        <div>
            <Navbar activePage="/md_dashboard"/>
        </div>

        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md mt-16">
                    <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Cross Check Mass Search</h1>
                </div>

                <div className='w-2/3 mx-auto'>               
                    {successMessage ?<div className="w-1/4 m-auto"><SuccessAlert title={'Export complete'} /></div>: <></>}
                    {showErrorMessage ?<div className="w-1/4 m-auto"><WarningAlert title={errorMessage} subtitle={'Contact Kevin for more details'} /></div>: <></>}
                   
                </div>

            
                <div className="mt-8 sm:mx-auto shadow sm:w-full sm:max-w-md">

                <div className="flex flex-col items-center mt-4 mb-6 gap-4">
                    <UserSelection check_reports={check_reports} defaultSelect={defaultSelect} selectedReportText={selectedReportText} setSelectedReportText={setSelectedReportText}/>
                    
                    {selectedReportText !== defaultSelect && selectedReportText !== '' ?
                        <UploadSearchFile selectedReportText={selectedReportText}  setUploadedData={setUploadedData}/>
                    :
                    <></>
                    }
                </div>

                


                    <div className="mb-12 flex justify-center m-auto">
                    {loading ? 
                                            <button
                            type="button"
                            className="px-4 py-2 border cursor-progress border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200"
                            disabled
                        >
                            Please Wait
                        </button>
                    
                    :
                    <></>
                    }
                    {loading === false && uploadedData.length > 0?
                    <div className='flex flex-col items-center gap-4'>
                        <p>Found <b>{uploadedData.length}</b> rows in file</p>
                        <button
                            type="button"
                            className="px-4 py-2 border cursor-pointer border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={handleSubmit}
                            >
                            Execute Report
                        </button>
                     </div>
                    :
                    <></>}
                    </div>
                </div>

            </div>

            <div>
                {loading ? 
                    <LoadingSpinner />

                    :
                    <></>
                }
            </div>



            <div>
                <GenericFooter />
            </div>
      </div>
    


        )
  
}
