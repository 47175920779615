import React from 'react'
import { useSelector } from 'react-redux'
import { RedirectToMyID } from './RedirectToMyID'
import { useHistory, useLocation } from 'react-router-dom'


export default function ProtectedRoute(props) {
    

    const user = useSelector(state => state.user)
    const history = useHistory()
    const location = useLocation()



    

    //when there is no permission required then its ok 
    // if permission required then user must be signed in 
    // if not send them to MyID
    // when user doesnt exists then kick them to the landing page with state to alert user of their inabiliyt ot access page

    if (user.user === null){
        return RedirectToMyID()
    }

    
    if (props.requiredPermission === null){
        return (<props.component />) 
    }

    // instead of assigning permissions for function selection pages 
    // the user only needs to have a permission that is required for one of the pages
    // example, when user has "PO STATUS" permission then its fine that they get access/see AP Dashboard landing page
    if (Array.isArray(props.requiredPermission)){

        if (props.requiredPermission.some(element => user.permissions.includes(element))){
            return (<props.component />) 
        }

        //for(let i =0; i < props.requiredPermission.length; i++){
        //    if (user.permissions.includes(props.requiredPermission[i])){
        //        return (<props.component />) 
        //    }
        //};
        

    }else if (user.permissions.includes(props.requiredPermission)){

        return (<props.component />) 
    }



    if (location.pathname === '/'){
        return (<props.component />) 
    }else{
        history.push({pathname: '/', state :{permissionIssue: 'true'}})
        return null
    }

}
