import React, { useRef } from "react"


export default function InputField(props) {

    const inputFieldRef = useRef()

    const updateInputField = () =>{
        props.setSearchInput(inputFieldRef.current.value)
    }


    return (
      <div className='w-2/3 h-full rounded-md p-1 border border-gray-300 shadow-md'>
        <textarea
          rows={2}
          className="w-full h-full px-4 py-3"
          placeholder="Search for something"
          ref={inputFieldRef}
          onChange={updateInputField}
        />
      </div>
    )
  }

  