import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LandingPage from "./pages/landingPage/LandingPage";
import TaxDashboard from "./pages/taxDashboard/TaxDashboard";
import ProtectedRoute from './components/auth/ProtectedRoute'
import { RecieveSSOPostRequest } from "./components/auth/RecieveSSOPost";
import SatoReport from './pages/taxDashboard/pages/sato/SatoReport'
import WhtReport from './pages/taxDashboard/pages/wht/WhtReport'
import KspPspStructure from 'pages/fxDashboard/pages/pspStructure/KspPspStructure'
import FxDashboard from 'pages/fxDashboard/fxDashboard'
import MdDashboard from 'pages/mdDashboard/mdDashboard'
import MdReports from 'pages/mdDashboard/pages/mdReports/MdReports'
import VendorCustomerSearch from 'pages/mdDashboard/pages/vendorCustomerSearch/VendorCustomerSearch'
import ApDashboard from 'pages/apDashboard/apDashboard'
import PoStatus from 'pages/apDashboard/pages/poStatus/PoStatus'
import OpenInvoice from 'pages/apDashboard/pages/openInvoice/OpenInvoice'
import CashAllocation from 'pages/apDashboard/pages/cashAllocation/CashAllocation'
import GmmVerification from "pages/apDashboard/pages/gmmVerification/GmmVerification";
import GlDashboard from "pages/glDashboard/glDashboard"
import CashAlert from "pages/glDashboard/pages/cashAlert/CashAlert"
import LoadingSpinner from "components/loading/LoadingSpinner";
import Admin from "pages/admin/Admin";
import Crisk from 'pages/glDashboard/pages/crisk/Crisk'
import AcOperations from "pages/acOperations/acOperations";
import CostcenterSignature from "pages/acOperations/pages/costcenterSignature/costcenterSignature";
import BankLiabilities from "pages/glDashboard/pages/bankLiabilities/BankLiabilities";
import ItComponentScan from "pages/acOperations/pages/itComponentScan/itComponentScan"
import HgbReport from "pages/acOperations/pages/hgbReport/HgbReport";
import UserV4 from "pages/userV4/User";
import GeneralSAP from "pages/generalSAP/generalSAP";
import VendorVolumeSearch from "pages/acOperations/pages/vendorVolumeSearch/VendorVolumeSearch";
import TableDescriptions from "pages/generalSAP/tableDescriptions/TableDescriptions";
import AuditDashboard from "pages/auditDashboard/auditDashboard";
import VendorsWithDifferentBankAccounts from "pages/auditDashboard/pages/vendorsWithDifferentBanks/VendorsWithDifferentBanks";
import Paradigm from "pages/acOperations/pages/paradigm/Paradigm";
import MassChecks from "pages/mdDashboard/pages/massChecks/MassChecks";
import SalesDocChange from "pages/fxDashboard/pages/salesDocChange/SalesDocChange";
import GlobalOpenItems from "pages/apDashboard/pages/globalOpenItems/GlobalOpenItems";
import AuditUpload from "pages/auditDashboard/pages/auditUpload/AuditUpload";

const ErrorPage = React.lazy(() => import("pages/errorPage/ErrorPage"));

function App() {
  return (

    <BrowserRouter basename={"/sap_dashboard/"}>
      <Switch >


        <Route path="/sso"> <RecieveSSOPostRequest /> </Route>

        <ProtectedRoute exact path='/' component={LandingPage} requiredPermission={''} />

        <ProtectedRoute exact path='/tax_dashboard' component={TaxDashboard} requiredPermission={['SATO_REPORT','WHT_REPORT']} />
        <ProtectedRoute path='/tax_dashboard/sato' component={SatoReport} requiredPermission={'SATO_REPORT'} />
        <ProtectedRoute path='/tax_dashboard/wht' component={WhtReport} requiredPermission={'WHT_REPORT'} />


        <ProtectedRoute exact path='/fx_dashboard' component={FxDashboard} requiredPermission={['KSP_PSP_STRUCTURE','KSP_SALES_DOCUMENT_CHANGE']} />
        <ProtectedRoute path='/fx_dashboard/psp_structure' component={KspPspStructure} requiredPermission={'KSP_PSP_STRUCTURE'} />
        <ProtectedRoute path='/fx_dashboard/sales_document_change' component={SalesDocChange} requiredPermission={'KSP_SALES_DOCUMENT_CHANGE'} />
        
        <ProtectedRoute exact path='/md_dashboard' component={MdDashboard} requiredPermission={['MASTER_DATA_REPORTS','VENDOR_CUSTOMER_SEARCH_HQ','VENDOR_CUSTOMER_SEARCH_GLOBAL','MASS_CHECKS']} />
        <ProtectedRoute path='/md_dashboard/master_data_reports' component={MdReports} requiredPermission={'MASTER_DATA_REPORTS'} />
        <ProtectedRoute path='/md_dashboard/vendor_customer_search_hq' component={VendorCustomerSearch} requiredPermission={'VENDOR_CUSTOMER_SEARCH_HQ'} />
        <ProtectedRoute path='/md_dashboard/vendor_customer_search_global' component={VendorCustomerSearch} requiredPermission={'VENDOR_CUSTOMER_SEARCH_GLOBAL'} />
        <ProtectedRoute path='/md_dashboard/mass_checks' component={MassChecks} requiredPermission={'MASS_CHECKS'} />




        <ProtectedRoute exact path='/ap_dashboard' component={ApDashboard} requiredPermission={['GLOBAL_OPEN_ITEMS','PO_STATUS','OPEN_INVOICE','VENDOR_CUSTOMER_SEARCH_HQ','CASH_ALLOCATION','GMM_VERIFICATION']} />
        <ProtectedRoute path='/ap_dashboard/po_status' component={PoStatus} requiredPermission={'PO_STATUS'} />
        <ProtectedRoute path='/ap_dashboard/open_invoice' component={OpenInvoice} requiredPermission={'OPEN_INVOICE'} />
        <ProtectedRoute path='/ap_dashboard/vendor_customer_search_hq' component={VendorCustomerSearch} requiredPermission={'VENDOR_CUSTOMER_SEARCH_HQ'} />
        <ProtectedRoute path='/ap_dashboard/cash_allocation' component={CashAllocation} requiredPermission={'CASH_ALLOCATION'} />
        <ProtectedRoute path='/ap_dashboard/gmm_verification' component={GmmVerification} requiredPermission={'GMM_VERIFICATION'} />
        <ProtectedRoute path='/ap_dashboard/global_open_items' component={GlobalOpenItems} requiredPermission={'GLOBAL_OPEN_ITEMS'} />



        <ProtectedRoute exact path='/gl_dashboard' component={GlDashboard} requiredPermission={['CASH_ALERT','CRISK','BANK_LIABILITIES']} />
        <ProtectedRoute path='/gl_dashboard/cash_alert' component={CashAlert} requiredPermission={'CASH_ALERT'} />
        <ProtectedRoute path='/gl_dashboard/bank_liabilities' component={BankLiabilities} requiredPermission={'BANK_LIABILITIES'} />
        <ProtectedRoute path='/gl_dashboard/crisk' component={Crisk} requiredPermission={'CRISK'} />

        <ProtectedRoute exact path='/ac_operations' component={AcOperations} requiredPermission={['CC_ORGDB','IT_COMPONENT_SCAN', 'VENDOR_VOLUME_SEARCH','HGB_REPORT', 'PARADIGM']} />
        <ProtectedRoute path='/ac_operations/costcenter_signature' component={CostcenterSignature} requiredPermission={'CC_ORGDB'} />
        <ProtectedRoute path='/ac_operations/it_component_scan' component={ItComponentScan} requiredPermission={'IT_COMPONENT_SCAN'} />
        <ProtectedRoute path='/ac_operations/vendor_volume_search' component={VendorVolumeSearch} requiredPermission={'VENDOR_VOLUME_SEARCH'} />
        <ProtectedRoute path='/ac_operations/hgb_report' component={HgbReport} requiredPermission={'HGB_REPORT'} />
        <ProtectedRoute path='/ac_operations/fpa_dashboard/paradigm_upload' component={Paradigm} requiredPermission={'PARADIGM'} />
        
        <ProtectedRoute exact path='/audit_dashboard' component={AuditDashboard} requiredPermission={['AUDIT','SEA50_UPLOAD']} />
        <ProtectedRoute path='/audit_dashboard/vendor_with_different_bank' component={VendorsWithDifferentBankAccounts} requiredPermission={'AUDIT'} />
        <ProtectedRoute path='/audit_dashboard/sea50_upload' component={AuditUpload} requiredPermission={'SEA50_UPLOAD'} />


        <ProtectedRoute path='/admin' component={Admin} requiredPermission={'ADMIN'} />
        <ProtectedRoute path='/users' component={UserV4} requiredPermission={'ADMIN'} />

        <ProtectedRoute exact path='/general_sap' component={GeneralSAP} requiredPermission={null} />
        <ProtectedRoute exact path='/general_sap/table_descriptions' component={TableDescriptions} requiredPermission={null} />



        <Suspense fallback={<LoadingSpinner />} >
          <Route path="*"> <ErrorPage /> </Route>
        </Suspense>

      </Switch>
    </BrowserRouter>
  );
}

export default App;
