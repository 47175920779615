// receive the post binding from MyID
import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { RedirectToMyID } from './RedirectToMyID';
import ConvertSessionToUserData from './components/ConvertSessionToUserData';
import { userDispatch  } from 'state/action-creator/AuthCreator'

export function RecieveSSOPostRequest(){
    const dispatch = useDispatch();
    const history = useHistory();
    const actions = bindActionCreators({userDispatch}, dispatch)



    useEffect( () =>{
        ConvertSessionToUserData().then( data=>{
            //when the session isnt found then return the kick the user back to My ID
            if (data === null ){
                history.push({pathname: '/', state :{permissionIssue: 'true'}});
                return null
            }else if ('ERROR' in data){
                return RedirectToMyID();
            }

            actions.userDispatch('user', data.user)

            if (data.validUser){
                actions.userDispatch('permissions', data.permissions)
            }else{
                history.push({pathname: '/', state :{permissionIssue: 'true'}})
  
            }

            history.push({pathname: '/'})
          return null            

       })

    },[])
    




    return(

        <div className="flex mt-36">
                <div className="m-auto animate-spin rounded-full h-48 w-48 mt-16 border-t-2 border-b-2 border-purple-500"></div>
        </div>
    )

}



