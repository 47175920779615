import React, {useEffect, useState} from 'react'
import Navbar from 'components/navigation/navbar/Navbar'
import DropdownSelection from './components/dropdownMenu'
import { ArrowCircleDownIcon, ArrowCircleUpIcon } from '@heroicons/react/outline'
import { exportToExcel } from './supportFunctions/exportToExcel'
import * as XLSX from 'xlsx';
import ReportTable from 'components/reportTable/reportTable'
import GenericFooter from 'components/navigation/footer/GenericFooter'
import { AxiosImportReleaseMatrix, AxiosCreatedFilteredOrgReport,AxiosCreatedUnfilteredOrgReport, AxiosGetRequiredAre, axios_costcenter_signature_poll } from './supportFunctions/axiosRequests'
import SuccessAlert from 'components/alert/SuccessAlert'
import WarningAlert from 'components/alert/WarningAlert'
import LoadingSpinner from 'components/loading/LoadingSpinner'
import SelectRequiredAre from './components/selectRequiredAre'

export default function CostcenterSignature() {

    const [selectedAre, setSelectedAre] = useState([]) 

    const [selectedDropdown, setSelectedDropdown] = useState("")
    const [showDownloadButton, setShowDownloadButton] = useState(false)
    const [showUploadSection, setShowUploadSection] = useState(false)
    const [showPreviewData, setShowPreviewData] = useState(false)
    const [uploadedData, setUploadedData] = useState([]) 
    const [uploadInProcess, setUploadInProcess] = useState(false) 
    const [showSuccess, setShowSuccess] = useState(false) 
    const [successMessage, setSuccessMessage] = useState("") 
    const [showSelectRequiredAre, setShowSelectRequiredAre] = useState(false) 
    const [showError, setShowError] = useState(false) 
    const[ fileUrl, setFileUrl] = useState()
    const[ processUUID, setProcessUUID] = useState()
    const [downloadInProgress, setDownloadInProgress] = useState(false)
    const errorMessage = "There was an error. Try again or contact Kevin"
    const MatrixBucketColumns = ["DS_Key","DS_Text", "Business_Area_Level_1", "Business_Area_Level_2", "Business_Area_Level_3" ,"Release_Matrix","Release_Matrix_Bucket"]

    const startPolling = (uuid, pollCounter)=>{
        if (pollCounter === 24){
            setShowError(true)
            setDownloadInProgress(false)
            clearTimeout(startPolling)
            return 
        }
            axios_costcenter_signature_poll(uuid).then(data =>{
                if (data.error){
                    setShowError(true)
                    setDownloadInProgress(false)
                    return
                }
                if (!data.data.processing){
                    setFileUrl(data.data.fileUrl)
                    clearTimeout(startPolling)
                    return
                }
                setTimeout(()=>startPolling(uuid,pollCounter+1), 5000);
            })
    }


    const downloadTemplate = () =>{
      var data = [{}]
        exportToExcel(data,MatrixBucketColumns, "Upload template")
        setSuccessMessage("Template downloaded successfully")
        setShowSuccess(true)
        setShowError(false)
    }


    const uploadExcelData = async (e) =>{
        const file = e.target.files[0]
        const data = await file.arrayBuffer();
        const wb = XLSX.read(data);
        const ws = wb.Sheets[wb.SheetNames[0]]
        XLSX.utils.sheet_add_aoa(ws, [MatrixBucketColumns], {origin: "A1"})
    
        var tempWS = XLSX.utils.sheet_to_json(ws)
        
        //avoid non-string types on inserting into table
        for (let i = 0; i< tempWS.length; i++){
            Object.keys(tempWS[i]).forEach(key =>{
                tempWS[i][key]= String(tempWS[i][key]);
              });
        }

        if (tempWS.length > 0){
            setShowPreviewData(true)
            setUploadedData(tempWS)
        }
    }

    const uploadData = ()=>{
        setUploadInProcess(true)
        setShowError(false)
        setShowSuccess(false)
    

        AxiosImportReleaseMatrix(uploadedData).then(data =>{
            if (data.error){
                setShowSuccess(false)
                setUploadInProcess(false)
                setShowError(true)
                return
            }

      
            setShowSuccess(true)
            setUploadInProcess(false)
            setSuccessMessage("Upload Successful")
            return
        
        })
    }
    

    const createOrgDBReport = ()=>{
        setShowSuccess(false)
        setShowError(false)
        setDownloadInProgress(true)

        switch(selectedDropdown){

            case "downloadFilteredOrgDB":
                AxiosCreatedFilteredOrgReport().then(data =>{
                    if (data.error){
                        setShowError(true)
                        return
                    }
                    setProcessUUID(data.data.uuid)
                    return
                })
            break;

            case "downloadUnfilteredOrgDB":
                AxiosCreatedUnfilteredOrgReport().then(data =>{
                    if (data.error){
                        setShowError(true)
                        return
                    }
                    setProcessUUID(data.data.uuid)
                    return
                })
            break;

        default:
            console.log("switch not matching")
        }

    }


    useEffect(()=>{

        setShowError(false)
        setShowSuccess(false)

        switch(selectedDropdown){
            case "":
                break;
            case "uploadNewMatrix":
                setShowUploadSection(true)
                setShowDownloadButton(false)
                setShowSelectRequiredAre(false)
                break;
            case "downloadFilteredOrgDB":
                setShowPreviewData(false)
                setShowUploadSection(false)
                setShowDownloadButton(true)
                setShowSelectRequiredAre(false)
                break;
            case "downloadUnfilteredOrgDB":
                setShowPreviewData(false)
                setShowUploadSection(false)
                setShowDownloadButton(true)
                setShowSelectRequiredAre(false)
                break;
            case "updateRequiredAre":
                setShowSelectRequiredAre(true)
                setShowPreviewData(false)
                setShowDownloadButton(false)
                setShowUploadSection(false)
                break;
            default:
                setShowSelectRequiredAre(false)
                setShowPreviewData(false)
                setShowDownloadButton(false)
                setShowUploadSection(false)

        }
    },[selectedDropdown])


    useEffect(()=>{
        AxiosGetRequiredAre().then(data =>{
            if (data.error){
                setShowError(true)
                return
            }

            setSelectedAre([...data.data.data])
        })

    },[])

    useEffect(()=>{
        if(fileUrl){
            setDownloadInProgress(false)
            setShowSuccess(true)
            setSuccessMessage("Download complete")
            window.open(fileUrl, "_blank")
        }
    },[fileUrl])

    useEffect(()=>{
        if(processUUID){
            startPolling(processUUID, 0)
        }
    },[processUUID])

  return (
    <div>
        <Navbar activePage="/ac_operations" />
        
        <div className='mt-20 shadow-lg w-1/2 mx-auto p-20 rounded-md'>

            <div className='w-1/2 mx-auto mb-4 -mt-6'>
                {showSuccess ?
                    <SuccessAlert title={successMessage} />
                    :
                    <></>
                }

                {showError ?
                    <WarningAlert title={errorMessage} />
                    :
                    <></>    
                }
            </div>
           



            <div className='text-3xl font-semibold mx-auto'>
                <h2 className='text-center'>OrgDB Hierarchy Report</h2>
                
            </div>  

            <div className='mt-6 w-1/2 min-w-1/2 mx-auto flex justify-center'>
                    <DropdownSelection setSelectedReportName={setSelectedDropdown}/>
            </div>

    {showUploadSection ?
    <div className='flex lg:gap-12 md:gap-4 justify-center'>
        <div className="flex mt-6 items-center justify-center bg-grey-lighter">
            <label className="lg:w-64 md:w-24 flex flex-col items-center px-4 py-6 bg-white text-blue-500 rounded-lg shadow-lg tracking-wide uppercase border border-blue-500 cursor-pointer hover:bg-blue-300 hover:text-white"
            onClick={downloadTemplate}
            >
                <ArrowCircleDownIcon className='h-8 w-8' />
                <span className="mt-2 text-base leading-normal text-center">Upload Template</span>

            </label>
        </div>

        <div className="flex mt-6 items-center justify-center bg-grey-lighter">
            <label className="lg:w-64 md:w-24 flex flex-col items-center px-4 py-6 bg-white text-blue-500 rounded-lg shadow-lg tracking-wide uppercase border border-blue-500 cursor-pointer hover:bg-blue-300 hover:text-white">
            <ArrowCircleUpIcon className='h-8 w-8' />
                <span className="mt-2 text-base leading-normal text-center">Upload release matrix</span>
                <input onChange={uploadExcelData} type='file' className="hidden" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
            </label>
        </div>
    </div>
    :
    <></>
    }

    {showPreviewData ?   
    <div className='flex justify-center mt-6 -mb-6'>


        {uploadInProcess ? 
            <button type="button" className="inline-flex cursor-progress items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-medium text-indigo-700  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                   Uploading please wait
            </button>
        :
            <button type="button" onClick={uploadData} className="inline-flex font-semibold items-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-base text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
                    DELETE & REPLACE <br/> 
                    Release matrix data
            </button>
        }
    </div>
    :
    <></>
    }

    {showDownloadButton && downloadInProgress === false ?
            <div className='mt-6 flex justify-center'>
                <button onClick={createOrgDBReport} type="button" className="inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        Start Download
                </button>
            </div>
        :
        <></>
        }

    {showDownloadButton && downloadInProgress ?
            <div className='mt-6 flex justify-center'>
                <button type="button" className="inline-flex cursor-wait items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        Please Wait
                </button>
            </div>
        :
        <></>
        }


        </div>
            {showPreviewData ?
                <div className='w-2/3 mx-auto mt-6'>
                    <ReportTable data={uploadedData} />
                </div>
            :
            <></>
            }

            {downloadInProgress ?
                <div>
                    <LoadingSpinner />
                </div>
                :
                <></>
            }

            {showSelectRequiredAre ?
                <SelectRequiredAre data={selectedAre} />
            :
            <></>}

            

            <GenericFooter />
            
        </div>
  )
}
