
import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';


const convertDate = (datepickerValue) =>{
    let year = datepickerValue.getFullYear()
    let month = datepickerValue.getMonth() + 1
    let day = datepickerValue.getDate()

    let formattedString = day + "/" + month + "/" + year 
    return (formattedString)
}



export const axios_ksp_sales_amount_change = async  (date_from, date_to) =>{

    let data = {
        fromDate : convertDate(date_from),
        toDate : convertDate(date_to)
    }

    
    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'fx_dashboard/sales_document_change/',
            headers: { 'content-type': 'application/json' },
            params: data,
            withCredentials: true,
        });
        
        if (response.status === 200){

            return {data : response.data, error: false}
        }else{
            return {data : [], error: true}
        }

    }catch(e){
        return {data : [], error: true}

    }
}



export const axios_open_fx_sales_doc_change_poll =async (uuid) =>{
    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'fx_dashboard/sales_document_change/poll/',
            headers: { 'content-type': 'application/json' },
            params: {processUUID : uuid},
            withCredentials: true,
        });
        
        if (response.status === 200 ){
            return {data: response.data, error : false}
            
        }else{
            return {data: response.data, error : false}
        }

    }catch(e){
        return {data: [], error : true}
    }
}
