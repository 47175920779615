import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';


export const axios_table_description = async  ( tableName ) =>{
    let data = {
    'tableName' : tableName
    } 

    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'general_sap/table_descriptions/',
            headers: { 'content-type': 'application/json' },
            params : data,
            withCredentials: true,
        });

        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}      
    }
}