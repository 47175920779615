import React, {useState, useEffect} from 'react'
import { axiosGetUserPermissions } from 'pages/userV4/support_functions/axiosGetPermissions'
import WarningAlert from 'components/alert/WarningAlert'

export default function CheckUserPermissions(props) {

    const [placeholderText, setPlaceholderText] = useState("")
    const [tempGID,setTempGID] = useState("")
    const [showError,setShowError]= useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const getPermissions=()=>{
        if (tempGID.length <= 5){
            setShowError(true)
            setErrorMessage("GID too short")
            return 
        }
        props.setUserGid(tempGID)

        axiosGetUserPermissions(tempGID, true).then(data =>{
            if(data.error){
                setShowError(true)
                setErrorMessage("Error getting permissions")
                return
            }
            props.setUserProfile(data.data.data)
            props.setStage(1)
        })

    }


    useEffect(()=>{
        if (props.userGid === ""){
            setPlaceholderText("User GID")
            return
        }
        setPlaceholderText(props.userGid)
    },[props.userGid])



    return(
        <div className='w-1/2 mx-auto'>
            <h2 className="text-xl font-semibold text-gray-500 text-left w-2/3 m-auto">Insert User GID</h2>
            {showError ? <WarningAlert title={errorMessage}/>:<></>}
            <div className="text-left pt-3 w-2/3 m-auto">
                <input onChange={e => setTempGID(e.target.value)}  placeholder={placeholderText} className="p-1 h-16 rounded-lg bg-gray-100 shadow-md focus:outline-none hover:bg-gray-200 focus:border-2 border-purple-500 w-full transition duration-200" />
            </div>

            <button onClick={getPermissions}  className="p-2 pr-5 pl-5 text-gray-800 font-semibold rounded border-purple-700 focus:ring-2 m-4 bg-purple-900 bg-opacity-60 hover:bg-opacity-90 hover:text-white transition duration-200">
                Update User Permissions
            </button>
        </div>
    )

}