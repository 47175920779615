import React from "react"

export default function ReportTablePagination(props) {


    const handleNaviation = (selection) =>{

        //dont change if the page is the first or last
        if (selection === 'back' && props.currentPage === 1){
            return 
        }
        
        if (selection === 'next' && props.totalRecords < (props.currentPage * 10) ){
            return 
        }


        if (selection === 'back'){
            props.setCurrentPage(props.currentPage - 1)
        }else if (selection === 'next'){
            props.setCurrentPage(props.currentPage + 1)
        }



        
    }




    return (
      <nav
        className="bg-white mt-1 px-4 py-3 items-center border-t border-gray-200 sm:px-6"
        aria-label="Pagination"
      >
        <div className="hidden sm:block">
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{props.currentPage ===1 ? props.currentPage : (props.currentPage - 1) * 10 + 1 }</span> to <span className="font-medium">{ ((props.currentPage - 1) * 10) + 10  > props.totalRecords ? props.totalRecords : ((props.currentPage - 1) * 10) + 10}</span> of{' '}
            <span className="font-medium">{props.totalRecords}</span> results
          </p>
        </div>

        <div className="sm:justify-end mt-4">
          <div
            className="relative cursor-pointer inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            onClick={()=>handleNaviation('back')}
          >
            Previous
          </div>
          <div
            className="ml-3 cursor-pointer relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            onClick={()=>handleNaviation('next')}
          >
            Next
          </div>
        </div>
    
      </nav>
    )
  }
