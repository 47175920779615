
import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';

export const axios_sato_report = async  (year, month ) =>{
    let data = {
    'fiscalYear' : year,
    'fiscalMonth' : month,
    } 

    
    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'tax_dashboard/sato_report/',
            headers: { 'content-type': 'application/json' },
            params : data,
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
       return  {data: [], error : true}

    }
}


