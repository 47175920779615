
import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';


export const axios_vendor_differentBank_report = async (are) =>{

    let requestData = {
        'selectedAre' : are
    }

    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'audit_dashboard/vendor_with_different_bank/',
            headers: { 'content-type': 'application/json' },
            params: requestData,
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}



