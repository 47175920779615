
import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';



export const axios_global_open_item = async (selectedAre) =>{

    let body = {'selectedAre' : selectedAre}

    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'ap_dashboard/global_open_items/',
            headers: { 'content-type': 'application/json' },
            params : body,
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}

