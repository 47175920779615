import axios from "axios";
import { sapDashboardAPIBaseuri } from "globals/variables/axiosVariables";

export async function AxiosUploadITComponentScanData(uploadData, user) {

    let postData = {
        data: uploadData,
        user : user
    }

    try{
        const response = await axios({
            method: 'POST',
            url: sapDashboardAPIBaseuri + 'ac_operations/it_component/upload_new_scan/',
            headers: { 'content-type': 'application/json' },
            data : postData,
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {error : false}
        }else{
            return {error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}


////// Get ssytem scan list

export async function AxiosViewITComponentScanData(system) {


    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'ac_operations/it_component/view_current_list/',
            headers: { 'content-type': 'application/json' },
            params : {"selectedSystem": system},
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}


/////// get systems with valid IT component scans 
export async function AxiosGetSystemsWithITComponentScans() {


    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'ac_operations/it_component/valid_systems/',
            headers: { 'content-type': 'application/json' },
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}



/////// run IT Component scan


export async function AxiosRunITComponentScan(system, fiscalYear) {

    let params = {
        "system" : system, 
        "fiscalYear" : String(fiscalYear),
    }

    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'ac_operations/it_component/run_scan/',
            headers: { 'content-type': 'application/json' },
            params : params,
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}


export const downloadJsonContents = async (urlPath) =>{
    const response = await axios({
        method: 'GET',
        url: urlPath,
        headers: { 'content-type': 'application/json' },
        responseType: 'application/json',
        transformResponse: (data) => JSON.parse(data),
    })
    if (response.status === 200){
        return response.data
    }
}
