import React, {useState, useEffect} from "react";
import SuccessAlert from "components/alert/SuccessAlert";
import WarningAlert from "components/alert/WarningAlert";
import { axiosGetAllPermissions } from "pages/userV4/support_functions/axiosGetPermissions";
import { axiosUpdateUserPermissions } from "pages/userV4/support_functions/axiosUpdateUserPermissions";


export default function UpdateUserPermission(props){
    const[permissions, setPermissions] = useState([])
    const[userPermissions, setUserPermissions] = useState([])
    const [showSuccessMessage, setShowSuccessMessage] = useState("")
    const [showSuccess, setShowSuccess] = useState(false)
    const [showStartover, setShowStartover] = useState(false)

    const [showErrorMessage, setShowErrorMessage] = useState("")
    const [showError, setShowError] = useState(false)

    const filterOnChange = (e) =>{
        let checkbox_value = e.target.value
        if (userPermissions.includes(checkbox_value)){
            let tem_array = []
            tem_array = userPermissions.filter(i => {
                return i !== checkbox_value
            })
            setUserPermissions(tem_array)
        }else{
            setUserPermissions([...userPermissions,checkbox_value])
        }
    }

    const startOver = () =>{
        props.setUserGid("")
        props.setStage(0)
    }

    const backButtonClick = ()=>{
        props.setStage(props.stage -1 )
    }

    const updatePermissionsButton = ()=>{

        axiosUpdateUserPermissions(props.userGid, userPermissions).then(data =>{
            if (data.error){
                setShowError(true)
                setShowErrorMessage("there was an error")
                return
            }

            setShowStartover(true)
            setShowSuccess(true)
            setShowSuccessMessage("successfully created")
        })

    }


    useEffect(()=>{
        axiosGetAllPermissions().then(data=>{
             if (data.error){
                console.log("cant read permissions")
                 return
             }
             setPermissions(data.data.data)
         })
     },[props.userGid])

     
     useEffect(()=>{
        setUserPermissions(props.userProfile)
    },[props.userGid])

    return(
        <div>
           {showSuccess ? <SuccessAlert title={showSuccessMessage} /> : <></>}
           {showError ? <WarningAlert title={showErrorMessage} /> : <></>}
            <table className="min-w-full max-h-1/2 divide-y divide-gray-200 mx-auto">
                <thead className="bg-gray-50">
                    <tr>
                    <th
                        scope="col"
                        className="px-6 py-3 text-left font-medium text-gray-500 tracking-wider"
                    >
                        Permission Name
                    </th>
                    <th
                        scope="col"
                        className="px-6 py-3 text-left  font-medium text-gray-500 tracking-wider"
                    >
                        Granted
                    </th>

                    </tr>
                </thead>
                <tbody>
                    {
                        permissions.map((i, index) => {
                            let checked = userPermissions.includes(i)
                            return(
                        <tr key={i} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                            <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-900 text-left">{i}</td>

                            <td className="px-6 py-4 whitespace-nowrap text-center">
                                <input className="text-purple-500 w-4 h-4 mr-2 focus:ring-purple-400 focus:ring-opacity-25 border border-gray-200 rounded text-left transition duration-500" 
                                checked={checked} onChange={filterOnChange} type="checkbox" value={ i } />
                            </td>
                        </tr>
                            )
                        }
                        )
                    }
                </tbody>
            </table>
            
            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6 flex justify-around">
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md bg-pink-800 opacity-60 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    onClick={backButtonClick}
                  >
                    Go Back
                  </button>

                  {showStartover ?
                  <button
                  type="submit"
                  className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  onClick={startOver}
                >
                  Create another user
                </button>
                  
                  : 
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    onClick={updatePermissionsButton}
                  >
                    Update permissions
                  </button>
                }
                </div>
        </div>
    )
}