import React, {useState, useEffect} from 'react'
import {AxiosGetSystemsWithITComponentScans, AxiosRunITComponentScan} from '../supportFunctions/axiosRequests'
import WarningAlert from 'components/alert/WarningAlert'
import GenericDropdown from './genericDropdown'


export default function RunITComponentScan(props) {
    const defaultDropdownMessage = "Please wait"
    const [validSystems, setValidSystems] = useState([defaultDropdownMessage])
    const [availableYears, setAvailableYears] = useState([defaultDropdownMessage])

    const [showWarning, setShowWarning] = useState(false)
    const [selectedSystemDropdownSelection,setSelectedSystemDropdownSelection] = useState("")
    const [selectedYearDropdownSelection,setSelectedYearDropdownSelection] = useState("")
    const [warningMessage, setWarningMessage] = useState("")


    const handleSubmit = () =>{
        setShowWarning(false)
        props.setShowDataTable(false)
        props.setShowSuccess(false)
        setWarningMessage("")
        let error = false
        let errorMesssage = ""

        if (selectedSystemDropdownSelection === "" || selectedSystemDropdownSelection === defaultDropdownMessage ){
          errorMesssage = "Select a system"
          error = true
        }

        if (selectedYearDropdownSelection === "" || selectedSystemDropdownSelection === selectedYearDropdownSelection ){
          if (errorMesssage.length >0){
            errorMesssage += ' and fiscal year'
          }else{
            errorMesssage = "Select a Fiscal Year"
          }
          
          error = true
        }

        if (error){
          setShowWarning(true)
          setWarningMessage(errorMesssage)
          return
        }

        props.setShowLoading(true)

        AxiosRunITComponentScan(selectedSystemDropdownSelection, selectedYearDropdownSelection).then(data =>{
            if (data.error){
              setShowWarning(true)
              setWarningMessage("Please contact Bernd or Kevin")
              props.setShowLoading(false)
                return
            }
            props.setFileUrl(data.data.fileUrl)
            props.setJsonTableUrl(data.data.jsonUrl)
        })

        return 
    }

    
    useEffect(()=>{
        AxiosGetSystemsWithITComponentScans().then(data =>{
          setValidSystems(data.data.data)
        })
      },[])

      useEffect(() =>{

        let date = new Date()


        if (date.getMonth() >= 9){
          setAvailableYears([(date.getFullYear() + 1 ).toString(), date.getFullYear().toString(), (date.getFullYear() - 1).toString()])
        }else{
          setAvailableYears([date.getFullYear().toString(), (date.getFullYear() - 1).toString()])
        }

    },[])

  return (
    <div className='mt-6 min-w-1/2 flex-col'>

      <div className='flex justify-center mb-4'>
        {showWarning ? <WarningAlert title={warningMessage} /> : <></>}
      </div>



      <div className='flex justify-center mx-auto z-50 gap-4 w-1/2'>
        <GenericDropdown defaultText={"Select System"} setSelectedDropdownSelection={setSelectedSystemDropdownSelection}  dropdownSelections={validSystems} />
      
        <GenericDropdown defaultText={"Select Fiscal Year"} setSelectedDropdownSelection={setSelectedYearDropdownSelection}  dropdownSelections={availableYears} />
      </div>


      <div className='flex justify-center mt-4 z-0'>
        {props.showLoading  ?
              
                  <button
                  type="button"
                  className="inline-flex cursor-progress items-center rounded-md border border-transparent bg-indigo-400 px-4 py-2 text-base font-medium text-white shadow-sm"
                >
                  Please wait
                </button>
        :
                  <button
                  type="button"
                  className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                  onClick={handleSubmit}
                >
                  Run Component Scan
                </button>
        }
      </div>



      </div>


  )
}
