// update the user 
export const userDispatch = (type, data) =>{

    if (type === 'user'){

        return (dispatch) =>{
            dispatch({
            type: 'user',
            data: data   
            })
        }
    }else if (type ==='permissions'){

        return (dispatch) =>{
            dispatch({
            type: 'permissions',
            data: data   
            })
        }


    }




}


