import React, {Suspense} from 'react'
import Navbar from 'components/navigation/navbar/Navbar'
import LoadingSpinner from 'components/loading/LoadingSpinner';
const FunctionalitySelections = React.lazy(() => import('pages/fxDashboard/FunctionalitySelection'));

export default function FxDashboard() {
    return (
        <div>
            <div>
                <Navbar activePage="/fx_dashboard"/>
            </div>
            
            <div className="bg-white">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                    <div className="text-center">
                    <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                        FX related reports
                    </p>
                    <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
                        One stop shop for FX related reports
                    </p>
                    </div>
                </div>
            </div>

            <div className='w-10/12 m-auto'>
                <Suspense fallback={<LoadingSpinner />} >
                    <FunctionalitySelections />
                </Suspense>
            </div>

        </div>
    )
}
