
import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';

export const axios_vendor_customer_saerch = async  (reportName, searchField, searchInput, global ) =>{

    let requestData ={
        'searchInput' : searchInput,
        'searchField' : searchField
    }

    let requestUri;
    switch (reportName){

        case 'vendor_search':
            if (global){
                requestUri = "md_dashboard/vendor_search/global/"
                break;
            }
            requestUri = "md_dashboard/vendor_search/"
            break;
        case 'customer_search':
            if (global){
                requestUri = "md_dashboard/customer_search/global/"
                break;
            }
            requestUri = "md_dashboard/customer_search/"
            break; 
        
    }


    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + requestUri,
            headers: { 'content-type': 'application/json' },
            params : requestData,
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}



export const downloadJsonContents = async (urlPath) =>{
    const response = await axios({
        method: 'GET',
        url: urlPath,
        headers: { 'content-type': 'application/json' },
        responseType: 'application/json',
        transformResponse: (data) => JSON.parse(data),
    })
    if (response.status === 200){
        return response.data
    }
}
