import React, {useRef, useState, useEffect} from "react";
import Navbar from "components/navigation/navbar/Navbar";
import LoadingSpinner from "components/loading/LoadingSpinner";
import {axios_vendorVolumeSearch, axios_poll } from 'pages/acOperations/pages/vendorVolumeSearch/support_functions/axiosRequests'
import SuccessAlert from "components/alert/SuccessAlert";
import WarningAlert from "components/alert/WarningAlert";
import GenericFooter from "components/navigation/footer/GenericFooter";
import UserSelections from "./components/userSelections";
import UploadSearch from "./components/uploadSearch";

export default function VendorVolumeSearch(){


    const fromYearRef = useRef()
    const fromMonthRef = useRef()
    const toYearRef = useRef()
    const toMonthRef = useRef()

    const [fromError, setFromError] = useState(false)
    const [toError, setToError] = useState(false)    
    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)

    const [fromFiscalYear, setFromFiscalYear] = useState([])
    const [fromFiscalPeriod, setFromFiscalPeriod] = useState([])
    const [toFiscalYear, setToFiscalYear] = useState([])
    const [toFiscalPeriod, setToFiscalPeriod] = useState([])
    const [uploadedData, setUploadedData] = useState([])
    const [processUUID,setProcessUUID] = useState("")
    const [fileUrl,setFileUrl] = useState("")

    const startPolling = (uuid, pollCounter)=>{
        if (pollCounter === 14){
            setErrorMessage(true)
            setLoading(false)
            clearTimeout(startPolling)
            return 
        }
            axios_poll(uuid).then(data =>{
                if (data.error){
                    setErrorMessage(true)
                    setLoading(false)
                    return
                }
                if (!data.data.processing){
                    setFileUrl(data.data.fileUrl)
                    clearTimeout(startPolling)
                    return
                }
                setTimeout(()=>startPolling(uuid,pollCounter+1), 5000);
            })
    }


    const handleSubmit = async () =>{
        let error = false
        setLoading(false)
        setSuccessMessage(false)
        setFromError(false)

        //check to make sure correct year and month are selected 
        //this is just makes sure they actually selected somethin
        if (!fromFiscalYear.includes(fromYearRef.current.value)){
            setFromError(true)
            error = true
        }

        if (!fromFiscalPeriod.includes(fromMonthRef.current.value)){
            setFromError(true)
            error = true
        }

        if (!toFiscalYear.includes(toYearRef.current.value)){
            setToError(true)
            error = true
        }
        
        if (!toFiscalPeriod.includes(toMonthRef.current.value)){
            setToError(true)
            error = true
        }

        if (error === false){
            setLoading(true)
            axios_vendorVolumeSearch(fromYearRef.current.value, fromMonthRef.current.value, toYearRef.current.value, toMonthRef.current.value,uploadedData).then( data =>{
                    if (data.error){
                        setErrorMessage(true)
                        return
                    }
                    setProcessUUID(data.data.uuid)

                    return
                })
        }else{
            return null
            
        }
    }


    // assign the correct values to the dropdown
    useEffect(() =>{
        let date = new Date()
        if (date.getMonth() >= 9){
            setFromFiscalYear([(date.getFullYear() + 1 ).toString(), date.getFullYear().toString(), (date.getFullYear() - 1).toString()])
            setToFiscalYear([(date.getFullYear() + 1 ).toString(), date.getFullYear().toString(), (date.getFullYear() - 1).toString()])
        }else{
            setFromFiscalYear([date.getFullYear().toString(), (date.getFullYear() - 1).toString()])
            setToFiscalYear([date.getFullYear().toString(), (date.getFullYear() - 1).toString()])
        }
        setFromFiscalPeriod(['1','2','3','4','5','6','7','8','9','10','11','12','13-16'])
        setToFiscalPeriod(['1','2','3','4','5','6','7','8','9','10','11','12', '13-16'])
    },[])


    useEffect(()=>{
        if(processUUID){
            startPolling(processUUID, 0)
        }
    },[processUUID])


    useEffect(()=>{
        if(fileUrl){
            window.open(fileUrl, "_blank")
            setLoading(false)
            setSuccessMessage(true)
        }
    },[fileUrl])


    return (
        <div>

            <div>
                <Navbar activePage="/ac_operations"/>
            </div>


            <div className="min-h-full flex flex-col justify-center py-4 sm:px-6 lg:px-8 m-4 shadow-md w-1/3 rounded-md mx-auto">
                <div className="sm:mx-auto sm:w-full sm:max-w-md mt-4">
                    <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Vendor/Customer GL Search</h1>
                </div>
                
                {successMessage ?<div className="w-2/3 m-auto"><SuccessAlert title={'Export complete'} /></div>: <></>}

                {errorMessage ?<div className="w-2/3 m-auto"><WarningAlert title={'There was an error'} subtitle={'Contact Bernd or Kevin'} /></div>: <></>}


                <UserSelections
                setSuccessMessage={setSuccessMessage}
                setToError={setToError}
                setFromError={setFromError}
                fromYearRef={fromYearRef}
                fromMonthRef={fromMonthRef}
                toYearRef={toYearRef}
                toMonthRef={toMonthRef}
                fromError={fromError}
                fromFiscalYear={fromFiscalYear}
                fromFiscalPeriod={fromFiscalPeriod}
                toError={toError}
                toFiscalYear={toFiscalYear}
                toFiscalPeriod={toFiscalPeriod}
                />

                <UploadSearch setUploadedData={setUploadedData} />

                {uploadedData.length > 0 ?
                <div className="text-center m-2">
                    Search for: {uploadedData.length} vendors/customers
                </div>
                : 
                <></>
                }

                {uploadedData.length > 0 ?
                    <div className="mb-12 mt-5 flex justify-center m-auto">
                        {loading ? 
                            <button
                            type="button"
                            className="px-4 py-2 border cursor-progress border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200"
                            disabled
                            >
                                Please Wait
                            </button>
                
                        :
                            <button
                                type="button"
                                className="px-4 py-2 border cursor-pointer border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={handleSubmit}
                            >
                                Execute Search
                            </button>

                            }
                    </div>
                :
                <></>
                }



            </div>

            <div>
                {loading ? 
                    <LoadingSpinner />

                    :
                    <></>
                }
            </div>


            <div>
                <GenericFooter />
            </div>
      </div>
    )
}


