import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';


export const axios_crisk = async  (are, sapSystem, selectedYear, selectedPeriod ) =>{
    let data = {
    'are' : are,
    'sapSystem' : sapSystem,
    'gjahr' : selectedYear, 
    'monat': selectedPeriod,
    } 

    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'gl_dashboard/crisk/',
            headers: { 'content-type': 'application/json' },
            params : data,
            withCredentials: true,
        });
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}


export const axios_crisk_get_dropdowns = async () =>{


    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'gl_dashboard/crisk_are_selection/',
            headers: { 'content-type': 'application/json' },
            withCredentials: true,
        });
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
      
    }
}
