import React, {useState, useRef} from 'react'
import Navbar from 'components/navigation/navbar/Navbar'
import SuccessAlert from 'components/alert/SuccessAlert'
import WarningAlert from 'components/alert/WarningAlert'
import LoadingSpinner from 'components/loading/LoadingSpinner'
import GenericFooter from 'components/navigation/footer/GenericFooter'
import { axios_table_description } from './supportFunctions/axios_table_descriptions'

export default function TableDescriptions() {
    const [showLoading, setShowLoading] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [showWarning, setShowWarning] = useState(false)
    const [warningMessage, setWarningMessage] = useState("")
    const userInput = useRef("")



    const handleSubmit = ()=>{

        if (userInput.current.value === ""){
            setShowWarning(true)
            setWarningMessage("Input is blank")
            return
        }

        setShowLoading(true)
        setShowWarning(false)
        setShowSuccess(false)

        axios_table_description(userInput.current.value).then(data =>{
            setShowLoading(false)
            if (data.error){
                setWarningMessage("There was an error")
                setShowWarning(true)
                return
            }

            window.open(data.data.fileUrl, "_blank")
            setShowSuccess(true)
            return 
        })
    }


  return (
    <div>
        <Navbar activePage='/general_sap'/>

        
        <div className='flex justify-center mt-16'>
            <div className='w-1/3 shadow-lg p-6 flex-col'>
                
                <div className='w-2/3 mx-auto'>
                    {showSuccess ? <SuccessAlert title="Export complete"/> : <></>}
                    {showWarning ? <WarningAlert title={warningMessage}/> : <></>}
                </div>

                <h1 className='text-3xl font-bold text-center'>General SAP Descriptions</h1>



                <div className='mx-auto flex flex-col justify-center mt-6 w-1/2 mb'>

                    <label htmlFor="tableName" className="block text-lg font-semibold leading-6 text-gray-900 text-center">
                        SAP Table Name
                    </label>

                    <div className="relative mt-2 rounded-md shadow-sm mb-6">
                        <input 
                        ref={userInput}
                      className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />

                </div>
                

                {showLoading ?
                    <button  type="button"
                        className="inline-flex w-2/3 cursor-wait mx-auto items-center text-ellipsis rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                    Please wait
                    </button>
                        :
                    
                    <button type="button"
                    onClick={handleSubmit}
                        className="inline-flex w-2/3 items-center mx-auto text-ellipsis rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                        Download Table Descriptions
                    </button>
                    }

                </div>

            </div>
        </div>

        {showLoading ?
            <LoadingSpinner />
        :
        <></>
        }
        

        <GenericFooter />
    </div>
  )
}
