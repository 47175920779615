
import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';


export const Axios_gmm_verificaiton = async (gmmCheckRows) => {

    let body = {
        'data': gmmCheckRows
    }

    try{
        const response = await axios({
            method: 'POST',
            url: sapDashboardAPIBaseuri + 'ap_dashboard/gmm_verification/',
            headers: { 'content-type': 'application/json' },
            data : body,
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}

export const axios_gmm_verification_poll =async (uuid) =>{
    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'ap_dashboard/gmm_verification/poll/',
            headers: { 'content-type': 'application/json' },
            params: {processUUID : uuid},
            withCredentials: true,
        });
        
        if (response.status === 200 ){
            return {data: response.data, error : false}
            
        }else{
            return {data: response.data, error : false}
        }

    }catch(e){
        return {data: [], error : true}
    }
}

