import React, {useState} from "react";
import Navbar from "components/navigation/navbar/Navbar";
import LoadingSpinner from "components/loading/LoadingSpinner";
import {axios_psp_structure_report } from 'pages/fxDashboard/pages/pspStructure/support_functions/axiosRequests'
import SuccessAlert from "components/alert/SuccessAlert";
import WarningAlert from "components/alert/WarningAlert";
import GenericFooter from "components/navigation/footer/GenericFooter";

export default function KspPspStructure(){

    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const handleSubmit = async () =>{
        let error = false
        setLoading(false)
        setSuccessMessage(false)

        if (error){
            return 
        }
        setLoading(true)

        axios_psp_structure_report().then( data =>{
            setLoading(false)
            if (!data.error){                
                window.open(data.data.url, "_blank")
                setSuccessMessage(true)
            }else{
                setErrorMessage(true)
            }
        })

    }


    return (
        <div>

            <div>
                <Navbar activePage="/fx_dashboard"/>
            </div>


            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md mt-16">
                    <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">KSP PSP Structure</h1>
                </div>
                
                {successMessage ?<div className="w-1/4 m-auto"><SuccessAlert title={'Export complete'} /></div>: <></>}

                {errorMessage ?<div className="w-1/4 m-auto"><WarningAlert title={'There was an error'} subtitle={'Contact Bernd or Kevin'} /></div>: <></>}

                <div className="mt-8 sm:mx-auto shadow sm:w-full sm:max-w-md">


                    <div className="mb-12 mt-8 flex justify-center m-auto">
                    {loading ? 
                                            <button
                            type="button"
                            className="px-4 py-2 border cursor-progress border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200"
                            disabled
                        >
                            Please Wait
                        </button>
                    
                    :
                        <button
                            type="button"
                            className="px-4 py-2 border cursor-pointer border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={handleSubmit}
                        >
                            Generate report
                        </button>

                    }
                    </div>
                </div>

            </div>

            <div>
                {loading ? 
                    <LoadingSpinner />

                    :
                    <></>
                }
            </div>

            <div>
                <GenericFooter />
            </div>
      </div>
    )
}


