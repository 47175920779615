import React, {useState, useEffect} from 'react'
import MultiSelectDropdown from 'pages/apDashboard/pages/openInvoice/components/multiSelectDropdown'
import SingleSelectDropdown from 'pages/apDashboard/pages/openInvoice/components/singleSelectDropdown'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";




export default  function UserMenu(props) {


    const [showDateFields, setShowDateFields] = useState(false)
    const [systemDropdown, setSystemDropdown] = useState([])
    const [compCodeDropdown, setCompCodeDropdown] = useState([])
    const [superExtractUser, setSuperExtractUser] = useState(false)

    //be careful changing these defualts, they are type checked in python in django
    const searchFieldDropdown = [
        [ 'REF_DOC_NO', 'Reference Number' ],
        [ 'VENDOR_NUMBER', 'Vendor Number' ],
        [ 'PO_NUMBER', 'PO Number' ],
        [ 'VBUND', 'VBUND' ],
        [ 'CLEARING_DOCUMENT', 'Clearing Document' ],
        [ 'IFA', 'IFA Number' ],
      ]
    const clearedDropdown = [
        [ '', 'All' ],
        [ 'CLEARED', 'Cleared' ],
        [ 'UNCLEARED', 'Uncleared' ],      
      ]

      const invoiceDropdown = [
        [ '', 'All' ],
        [ 'NOT_IN_WORKFLOW', 'Not in workflow' ],
        [ 'IN_WORKFLOW', 'In a workflow' ],    
        [ 'ERRORS', 'Errors' ],    
        [ 'REJECTED', 'Rejected' ],        
      ]

      const paymentBlockStatus =[
        [ '', 'All' ],
        [ 'PAYMENT_BLOCK', 'Has payment block' ],
        [ 'NO_PAYMENT_BLOCK', 'No payment block' ],    
      ]


      useEffect(()=>{
         let compCodeSet = new Set()
         let systemSet = new Set()

         //convert both company codes and systems into sets 
        for (let i = 0; i<props.initCompCodeData.length; i++){
            compCodeSet.add(props.initCompCodeData[i].companyCode)
            systemSet.add(props.initCompCodeData[i].sapSystem)
        }

        //set system dropdown
        let temp_systemsSet = []
        systemSet.forEach(i =>{
            temp_systemsSet.push([i , i])
        })
        setSystemDropdown(temp_systemsSet)

        //set company code dropdown
        let temp_compCode = []
        compCodeSet.forEach(i =>{
            temp_compCode.push([i , i])
        })
        setCompCodeDropdown(temp_compCode)
      
    },[props.initCompCodeData])


    useEffect(()=>{
       ///removed the super extractor featuer with the Open Invoice V2 version since it shouldnt be required anymore
       
        // try{
        //    if (props.user.user.gid === 'Z003YW6B' || props.user.user.gid === 'Z0001MPZ'){
        //        setSuperExtractUser(true)
        //    }
        //}catch(e){
        //    console.log(e)
            setSuperExtractUser(false)
       //}

    },[props.user])



  return (
        <div>

            <div className='mb-6 flex justify-around'>
                <div className='flex flex-col'>
                    <MultiSelectDropdown setState={props.setSelectedSapSystems}  subTitle ={"SAP Systems"} dropDownSelections={systemDropdown} defaultText={'All'} defaultField={[]}/>
                    <MultiSelectDropdown setState={props.setSelectedCompCode}  subTitle ={"Company Codes"} dropDownSelections={compCodeDropdown} defaultText={'All'} defaultField={[]}/>
                    <div className='flex flex-col justify-left ml-[10%]'>
                        <input type="checkbox" className='h-5 w-5 mb-1' checked={showDateFields} onChange={() =>setShowDateFields(!showDateFields)}/>
                        <label> Show Date Filters</label>
                    </div>

                </div>

                <div className='flex flex-col'>
                    <SingleSelectDropdown setState={props.setSelectedSearchField}  subTitle ={"Search Fields"} dropDownSelections={searchFieldDropdown} defaultText={'Reference Number'} defaultField={'REF_DOC_NO'} />
                    <SingleSelectDropdown setState={props.setSelectedClearedStatus} subTitle ={"Cleared Status"} dropDownSelections={clearedDropdown} defaultText={'All'} defaultField={''}/>
                    {superExtractUser ?
                    <div>
                    <MultiSelectDropdown setState={props.setSelectedInvoiceStatus} subTitle ={"Invoice Status"} dropDownSelections={invoiceDropdown} defaultText={'All'} defaultField={[]}/>
                    <MultiSelectDropdown setState={props.setSelectedPaymentBlockStatus} subTitle ={"Payment block status"} dropDownSelections={paymentBlockStatus} defaultText={'All'} defaultField={[]}/>
                    </div>
                    :
                    <></>}



                </div>


            </div>







            {showDateFields ?
                <div className='flex justify-around ml-[10%]'>
                    <div className='max-w-[50%]'>
                            <DatePicker
                                className='border mt-6'
                                dateFormat="dd/MM/yyyy"
                                selected={props.startDate}
                                onChange={(date) => props.setStartDate(date)}
                            />

                            <div>
                                Date From <br/> (dd/mm/yyyy)
                            </div>

                        </div>


                        <div className='max-w-[50%]'>
                                <DatePicker
                                    className='border mt-6'
                                    dateFormat="dd/MM/yyyy"
                                    selected={props.endDate}
                                    onChange={(date) => props.setEndDate(date)}
                                />

                                <div>
                                    Date To <br/> (dd/mm/yyyy)
                                </div>
                        </div>
                </div>

                :
                <>
                </>
            }
        </div>
  )
}

