import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';

export async function AxiosImportReleaseMatrix(releaseData) {

    let postData = {
        data: releaseData
    }

    try{
        const response = await axios({
            method: 'POST',
            url: sapDashboardAPIBaseuri + 'ac_dashboard/org_db/cost_center_signature_update_release_matrix/',
            headers: { 'content-type': 'application/json' },
            data : postData,
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}


export async function AxiosCreatedFilteredOrgReport() {
    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + "ac_dashboard/org_db/cost_center_signature_filtered/",
            headers: { 'content-type': 'application/json' },
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}


export async function AxiosCreatedUnfilteredOrgReport() {
    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + "ac_dashboard/org_db/cost_center_signature_unfiltered/",
            headers: { 'content-type': 'application/json' },
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}


export async function AxiosUpdateRequiredAres(requiredAre) {
    try{
        const response = await axios({
            method: 'POST',
            url: sapDashboardAPIBaseuri + 'ac_dashboard/org_db/cost_center_signature_update_are/',
            data : {"are" : requiredAre},
            headers: { 'content-type': 'application/json' },
            withCredentials: true,
        });
        
        if (response.status === 200 ){
            return {data: response.data, error : false}
            
        }else{
            return {data: response.data, error : false}
        }

    }catch(e){
        return {data: [], error : true}
    }
}


export async function AxiosGetRequiredAre() {
    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'ac_dashboard/org_db/selected_are/',
            headers: { 'content-type': 'application/json' },
            withCredentials: true,
        });
        
        if (response.status === 200 ){
            return {data: response.data, error : false}
            
        }else{
            return {data: response.data, error : false}
        }

    }catch(e){
        return {data: [], error : true}
    }
}

export const axios_costcenter_signature_poll =async (uuid) =>{
    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'ac_dashboard/org_db/cost_center_signature/poller/',
            headers: { 'content-type': 'application/json' },
            params: {processUUID : uuid},
            withCredentials: true,
        });
        
        if (response.status === 200 ){
            return {data: response.data, error : false}
            
        }else{
            return {data: response.data, error : false}
        }

    }catch(e){
        return {data: [], error : true}
    }
}

