import React from 'react'
import { Link } from 'react-router-dom'

export default function HeroSection() {


  const allDashboards =[
  ["General SAP", "/general_sap/"],
  ["AP Dashboard", "/ap_dashboard/"], 
  ["Master Data Dashboard", "/md_dashboard/"], 
  ["FX Dashboard", "/fx_dashboard/"], 
  ["Tax Dashboard", "/tax_dashboard/"], 
  ["GL Dashboard", "/gl_dashboard/"], 
  ["AC Operations Dashboard", "/ac_operations/"]
]



  return (
    <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
      <div className="text-center">
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          <span className="block xl:inline">SE AC</span>{' '}
          <span className="block text-indigo-600 xl:inline">SAP Dashboard</span>
        </h1>
        <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          Supporting the SE AC department through digitalization!
        </p>

        <dl className="mt-12 grid sm:grid-cols-1 sm:gap-6 md:grid-cols-2 md:gap-4  lg:grid-cols-4 lg:gap-4">

          {allDashboards.map((i, k) => {
            return (

              <div key={k}>
                <Link key={i[0]} to={i[1]} className="p-3">
                  <dt className='text-lg inline-flex items-center px-6 py-3 border border-transparent font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                    {i[0]}
                  </dt>
                </Link>
              </div>

            )

          })}

        </dl>

      </div>
    </main>

  )
}
