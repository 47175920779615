const snowflakeProject = 'PARADIGM'

export const createTable = (fileName, dataKeys)=>{

    let sqlStatement = `create or replace table DB_PRD_ANALYTICS.PS_AN_${snowflakeProject}.${fileName}(${dataKeys.join(' string,')}`

    sqlStatement = sqlStatement.substring(0, sqlStatement.length) + " string)"

    return sqlStatement
}


export const createInsertStatement = (fileName, dataKeys, data) =>{
    let sqlStatement = `insert into DB_PRD_ANALYTICS.PS_AN_${snowflakeProject}.${fileName} (${dataKeys.join(',')}) values`
    for(let i = 0 ; i< data.length; i++){
        let tempItem = "("
        dataKeys.forEach(j => {
            let val = data[i][j]
            
            if (val === undefined || val === null){
                val = ""
            }
           

            if (typeof val === 'string' || val instanceof String){
                val = val.replace(/\u0027/gi, '')
            }
            
            tempItem += `'${val}',`

            
        });
        sqlStatement +=  tempItem.substring(0, tempItem.length - 1) + "),"
    }

    sqlStatement = sqlStatement.substring(0, sqlStatement.length-1 )

    return sqlStatement

}