import React, {useState, useEffect} from "react";
import Navbar from "components/navigation/navbar/Navbar";
import LoadingSpinner from "components/loading/LoadingSpinner";
import {axios_master_data_reports, downloadJsonContents } from 'pages/mdDashboard/pages/mdReports/support_functions/axiosRequest'
//import { exportToExcel } from 'pages/mdDashboard/pages/mdReports/support_functions/exportToExcel'
import SuccessAlert from "components/alert/SuccessAlert";
import WarningAlert from "components/alert/WarningAlert";
import MdReportsDropdown from 'pages/mdDashboard/pages/mdReports/components/mdReportsDropdown'
import ReportTable from 'components/reportTable/reportTable'
import GenericFooter from "components/navigation/footer/GenericFooter";




export default function MdReports(){

    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [warningMessage, setWarningMessage] = useState('')
    const [showWarningMessage, setShowWarningMessage] = useState(false)
    const [selectedReportName, setSelectedReportName] = useState('')
    const [showDataTable, setShowDataTable] = useState(false)
    const [tableData, setTableData] = useState([])
    const [defaultColumnHeaders, setDefaultColumnHeaders] = useState(null)
    const [fileUrl, setFileUrl] = useState("")
    const [jsonUrl,setJsonUrl] = useState("")

      //export the data to excel 
      const exportDataToExcel = () =>{
        window.open(fileUrl, "_blank")
    }


    //handle the submit button
    const handleSubmit = async () =>{
        let error = false
        setLoading(false)
        setSuccessMessage(false)
        setShowDataTable(false)

        if (selectedReportName === ''){
            error = true
            setWarningMessage('You forgot to select something')
            setShowWarningMessage(true)
        }else{
            setShowWarningMessage(false)
        }

        let temp;
        //set the column headers depending on the report
        switch(selectedReportName){

            case 'blocked_vendors':
                temp =  ['SAP System', 'Mandt','Vendor Number','Vendor Name', 'Company Code' ,'Invoice GUID','Reference Number','Doc Date','Check 2-1', 'Check 2-2','Posting Date', 'Fiscal Year', 'Fiscal Period' ,'Fi/MM','Payment Block', 'Key']
            break;

            case 'venodr_missing_bank_accounts':
                temp =  ['SAP System', 'Mandt', 'Company Code', 'Vendor Number', 'Vendor Name', 'Invoice GUID', 'Reference Number', 'Fiscal Year', 'Document Date', 'Bank Account Number', 'Key']
            break;
            //use the report column names as default headers
            case 'payment_term_data':
                temp = ['SAP SYSTEM',	'TERM DEFINITION',	'MANDT',	'ZTAGG',	'ZTAG1',	'ZPRZ1',	'ZTAG2',	'ZPRZ2',	'ZTAG3',	'ZSTG1',	'ZSMN1',	'ZSTG2',	'ZSMN2',	'ZSTG3',	'ZSMN3',	'VENDOR CUSTOMER',	'SPLIT PAYMENT',	'PAYMENT TERM',	'Key']
            break;

            case 'orbian_report':
                temp = ['SAP System','Company Code','Vendor Number','Name','IFA','BANKS','BANKL','BANKN','BVTYP','BKREF','ZTERM','ZWELS','ZAHLS','HBKID','ZGRUP','Key']
            break;

            default:
                setDefaultColumnHeaders(['None'])
        }

        setDefaultColumnHeaders(temp)


        if (error === false){
            setLoading(true)

            axios_master_data_reports(selectedReportName).then( data =>{

                if (data.error){
                    setErrorMessage(true)
                    setLoading(false)
                    return
                }
                setFileUrl(data.data.fileUrl)
                setJsonUrl(data.data.jsonUrl)    
            })

        }else{
            return null
            
        }
    }

    // wait until the table data is updated in use state before shwoing table
    useEffect(() =>{

        if (tableData.length !== 0){
            setLoading(false)        
            setShowDataTable(true)
            return
        }
    },[tableData])


    useEffect(()=>{
        if (jsonUrl){
            downloadJsonContents(jsonUrl).then(data =>{
                setTableData(data)
            })
        }
    },[jsonUrl])



    return (
        <div>

            <div>
                <Navbar activePage="/md_dashboard"/>
            </div>


            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md mt-16">
                    <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Master Data Reports</h1>
                </div>
                
                {successMessage ?<div className="w-1/4 m-auto"><SuccessAlert title={'Export complete'} /></div>: <></>}
                {errorMessage ?<div className="w-1/4 m-auto"><WarningAlert title={'There was an error'} subtitle={'Contact Bernd or Kevin'} /></div>: <></>}
                {showWarningMessage ?<div className="w-1/4 m-auto"><WarningAlert title={warningMessage} subtitle={''} /></div>: <></>}



            
                <div className="mt-8 sm:mx-auto shadow sm:w-full sm:max-w-md">

                <div className="flex justify-center mt-4 mb-6">
                    <MdReportsDropdown setSelectedReportName={setSelectedReportName}/>
                </div>

                


                    <div className="mb-12 flex justify-center m-auto">
                    {loading ? 
                                            <button
                            type="button"
                            className="px-4 py-2 border cursor-progress border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200"
                            disabled
                        >
                            Please Wait
                        </button>
                    
                    :
                        <button
                            type="button"
                            className="px-4 py-2 border cursor-pointer border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={handleSubmit}
                        >
                            Generate report
                        </button>

                    }
                    </div>
                </div>

            </div>

            <div>
                {loading ? 
                    <LoadingSpinner />

                    :
                    <></>
                }
            </div>


            {showDataTable ? 
                <div className="w-2/3 m-auto mb-24">
                          <button
                                type="button"
                                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={exportDataToExcel}
                            > Export to excel</button>



                    <ReportTable data={tableData} columnHeaders={defaultColumnHeaders}  />
                </div>

                :
                <></>
            }

            <div>
                <GenericFooter />
            </div>
      </div>
    )
}


