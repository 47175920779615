import React, {useState, useEffect} from 'react'
import GenericDropdown from './genericDropdown'
import WarningAlert from 'components/alert/WarningAlert'
import {AxiosViewITComponentScanData, AxiosGetSystemsWithITComponentScans} from '../supportFunctions/axiosRequests'
import ReportTable from 'components/reportTable/reportTable'
import { ExportToExcel } from '../supportFunctions/exportToExcel'

function ViewComponentScanList(props) {
  const [selectedDropdownSelection,setSelectedDropdownSelection] = useState("")
  const [showWarning, setShowWarning] = useState(false)
  const [reportData, setReportData] = useState([])
  const [showPreviewTable, setShowPreviewTable] = useState(false)
  const [warningMessage, setWarningMessage] = useState("")
  const [validSystems, setValidSystems] = useState(["please wait"])




  const handleSubmit = () =>{
    setShowWarning(false)
    setShowPreviewTable(false)

    if (selectedDropdownSelection.length === 0){
      setShowWarning(true)
      setWarningMessage("Select system")
      return
    }

    props.setShowLoading(true)

    AxiosViewITComponentScanData(selectedDropdownSelection).then(data =>{
      props.setShowLoading(false)

      if (data.error){
        setShowWarning(true)
        setWarningMessage("There was an error getting your data")
        return 
      }
      setReportData(data.data.data)
      setShowPreviewTable(true)
    })
  }

  const exportToExcel = ()=>{
    ExportToExcel(reportData, ["Control Number", "SAP System", "Program Name", "User", "Creation Date"],`${selectedDropdownSelection} ITGC Component Scan`)
  }


  useEffect(()=>{
    AxiosGetSystemsWithITComponentScans().then(data =>{
      setValidSystems(data.data.data)
    })
  },[])


  return (
    <div className='mt-6 min-w-1/2 flex-col'>

      <div className='flex justify-center mb-4'>
        {showWarning ? <WarningAlert title={warningMessage} /> : <></>}
      </div>


      <div className='flex justify-center mb-4'>
        <h3 className='font-semibold'>View current program scan list </h3>
      </div>

      <div className='flex flex-col mx-auto w-1/6 mb-6'>
        <div className='mx-auto'>
          <p>Select System</p>
        </div>
        <GenericDropdown setSelectedDropdownSelection={setSelectedDropdownSelection}  dropdownSelections={validSystems} />
      
      </div>

      <div className='flex justify-center'>
   
        <button
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
          onClick={handleSubmit}
        >
          Get Scan list
        </button>

      </div>


      {showPreviewTable ?
      <div className='flex-col mt-6 '>
        <div className='flex justify-center mt-6'> 
   
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-sm font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={exportToExcel}
          >
            Export to excel
          </button>
        </div>

        <div className='flex justify-center mt-6'> 
        <ReportTable data={reportData} />  
        </div>
        
    </div>
    :
    <></>
  }

    </div>
  )
}

export default ViewComponentScanList