import React, {useState, useRef} from 'react'
import Navbar from 'components/navigation/navbar/Navbar'
import GenericFooter from 'components/navigation/footer/GenericFooter'
import LoadingSpinner from 'components/loading/LoadingSpinner'
import { axios_bank_liability } from './supportFunctions/axiox_bankLiabilitiy'
import SuccessAlert from 'components/alert/SuccessAlert'
import WarningAlert from 'components/alert/WarningAlert'

export default function BankLiabilities() {
    const quarterSelection =["Q1","Q2","Q3","Q4"]
    const yearSelection = ['2023','2024','2025']
    const [quarterError, setQuarterError] = useState(false)
    const [yearError, setYearError] = useState(false)
    const [showLoading, setShowLoading] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [showWarning, setShowWarning] = useState(false)

    const selectedQuarter = useRef()
    const selectedYear = useRef()

    const defaultQuarter = "Quarter"
    const defaultYear = "Year"

    const handleSubmit = ()=>{
        let errorFound = false
        setQuarterError(false)
        setYearError(false)
        setShowLoading(false)
        setShowWarning(false)
        setShowSuccess(false)

        const year = selectedYear.current.value
        const quarter = selectedQuarter.current.value
        
        if (quarter === defaultQuarter){
            setQuarterError(true)
            errorFound = true
        }

        if (year=== defaultYear){
            setYearError(true)
            errorFound = true
        }

        if (errorFound){
            return
        }
        setShowLoading(true)
        
        axios_bank_liability(quarter,year).then(data =>{
            setShowLoading(false)

            if (data.error){
                setShowWarning(true)
                return
            }

            window.open(data.data.fileUrl, "_blank")
            //exportToExcel(data.data,quarter,year)
            setShowSuccess(true)
            return 

        })
    }

  return (
    <div>
        <Navbar activePage='/gl_dashboard'/>

        <div className='flex justify-center mt-16'>
            <div className='w-1/3 shadow-lg p-6 flex-col'>
                
                <div className='w-2/3 mx-auto'>
                    {showSuccess ? <SuccessAlert title="Export exported"/> : <></>}
                    {showWarning ? <WarningAlert title="There was an error"/> : <></>}
                </div>

                <h1 className='text-3xl font-bold text-center'>Bank Liability Report</h1>

                <div className='flex justify-center gap-3'>
                    <div className='w-1/4 mt-6 '>
                        <label htmlFor="fp" className={`${quarterError ? 'text-red-600 animate-bounce' : 'bg-white text-gray-700'} block text-lg text-center font-medium border-b-2"`} >
                                    Select Quarter
                        </label>
                    
                        <select ref={selectedQuarter}
                            className="text-lg mt-1 block w-full pl-3 pr-10 py-2  border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                            <option className="text-lg">{defaultQuarter}</option>

                            {quarterSelection.map(i =>{
                            return <option key={i} value={i} className="text-lg">{i}</option>
                            })}
                        </select>
                    </div>

                    <div className='w-1/4 mt-6'>
                        <label htmlFor="fp" className={`${yearError ? 'text-red-600 animate-bounce' : 'bg-white text-gray-700'} block text-lg text-center font-medium border-b-2"`} >
                                    Select Year
                        </label>
                    
                        <select ref={selectedYear}
                            className="text-lg mt-1 block w-full pl-3 pr-10 py-2  border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                            <option className="text-lg">{defaultYear}</option>

                            {yearSelection.map(i =>{
                            return <option key={i} value={i} className="text-lg">{i}</option>
                            })}
                        </select>
                    </div>
                </div>

                <div className='mx-auto flex justify-center mt-6'>

                {showLoading ?
            
                <button onClick={handleSubmit} type="button"
                    className="inline-flex cursor-wait items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                   Please wait
                </button>
                    :
                    <button onClick={handleSubmit} type="button"
                    className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                    Generate Report
                </button>
                    }

                </div>

            </div>
        </div>

        {showLoading ?
            <LoadingSpinner />
        :
        <></>
        }
        

        <GenericFooter />
    </div>
  )

}

