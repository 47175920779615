import axios from 'axios'

import {sapDashboardAPIBaseuri} from 'globals/variables/axiosVariables'

/////////////////////////// convert session token into user data
export default async function  ConvertSessionToUserData(){
    
    try{
        const response = await axios({
            method: "GET",
            url: sapDashboardAPIBaseuri + "verify_user/",
            headers: { 
                'content-type': 'application/json' ,
            },
            mode: 'same-origin',
            withCredentials: true,
        })

        return  response.data;

    }catch(e){
        console.log(e)
        return null
    }

}
