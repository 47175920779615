/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'



//dont change these without looking at the go backend request (dont change the reportName field)


const vendor_selection = [
    {dropdownText : "Vendor Name",
     inputParameter : "vendorName"},
     {dropdownText : "Vendor Number",
     inputParameter : "vendorNumber"},
     {dropdownText : "Postal Code",
     inputParameter : "postalCode"},
     {dropdownText : "City",
     inputParameter : "city"},
     {dropdownText : "IFA Number",
     inputParameter : "ifa"},
     {dropdownText : "VAT Number",
     inputParameter : "vat"},
     {dropdownText : "Street",
     inputParameter : "street"},
     {dropdownText : "Country Code",
     inputParameter : "countryCode"},
     {dropdownText : "IBAN",
     inputParameter : "iban"},
     {dropdownText : "Bank Account",
     inputParameter : "bankAccount"},
]



export default function VendorDropdown(props) {

const defaultSelect = 'Make selection'
const [selectedReportText, setSelectedReportText] = useState(defaultSelect)


const handleSelection = (inputParameter, dropdownText) =>{


    if (inputParameter === defaultSelect) {
        props.setSearchField('')
        setSelectedReportText(defaultSelect)
    }else{
        props.setSearchField(inputParameter)
        setSelectedReportText(dropdownText)
    }
    

}


  return (
    <Menu as="div" className="relative inline-block text-left w-2/3">
      <div>
        <Menu.Button className="inline-flex w-full justify-between rounded-md border border-gray-300 shadow-sm px-4 py-3 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
          {selectedReportText}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 " aria-hidden="true" />
        </Menu.Button>
      </div>

    {/* main dropdown for the vendor/customer selection */}
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">

        {selectedReportText !== defaultSelect ?
            <Menu.Item>
                <div className={'text-gray-700 block px-4 py-2 text-sm hover:bg-gray-200 hover:text-gray-700'}
                onClick={() =>handleSelection(defaultSelect)}
                >
                    {defaultSelect}
                </div>
            </Menu.Item>
        :
        <></>
        }

              {vendor_selection.map((i, index) =>{

                return( 
                        <Menu.Item key={index}>

                            <div className={'text-gray-700 block px-4 py-2 text-sm hover:bg-gray-200 hover:text-gray-700'}
                            onClick={() =>handleSelection(i.inputParameter, i.dropdownText)}
                            value={i.inputParameter}
                            >
                                {i.dropdownText}
                            </div>

                        </Menu.Item>
                )
                })}
   
          </div>
        </Menu.Items>



      </Transition>

    </Menu>
  )
}
