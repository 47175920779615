import React, {useState, useEffect} from 'react'
import MultiSelectDropdown from 'pages/apDashboard/pages/openInvoice/components/multiSelectDropdown'
import SingleSelectDropdown from 'pages/apDashboard/pages/openInvoice/components/singleSelectDropdown'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";




export default  function UserMenu(props) {


    const [showDocumentCurrency, setShowDocumentCurrency] = useState(false)
    const [systemDropdown, setSystemDropdown] = useState([])
    const [compCodeDropdown, setCompCodeDropdown] = useState([])
    
    const vendorCustomer = [
        [ 'VENDOR', 'Vendor' ],
        [ 'CUSTOMER', 'Customer' ],
        [ 'BOTH', 'Both' ],
      ]


      const searchFields = [
        [ 'VENDOR_NAME', 'Name' ],
        [ 'DOCUMENT_AMOUNT', 'Document Currency Amount' ],
        [ 'REF_DOC_NO', 'Invoice Number (Ref Doc)' ],
        [ 'BANK_ACCOUNT', 'Bank Account Number' ],
      ]



    const [documentCurrencies, setDocumentCurrencies] = useState(
    [
    [ 'CLEARED', 'Cleared' ],
    [ 'UNCLEARED', 'Uncleared' ],
    [ [], 'All' ]
])


      useEffect(()=>{
         let compCodeSet = new Set()
         let systemSet = new Set()

         //convert both company codes and systems into sets 
        for (let i = 0; i<props.initCompCodeData.length; i++){
            compCodeSet.add(props.initCompCodeData[i].companyCode)
            systemSet.add(props.initCompCodeData[i].sapSystem)
        }

        //set system dropdown
        let temp_systemsSet = []
        systemSet.forEach(i =>{
            temp_systemsSet.push([i , i])
        })
        setSystemDropdown(temp_systemsSet)

        //set company code dropdown
        let temp_compCode = []
        compCodeSet.forEach(i =>{
            temp_compCode.push([i , i])
        })
        setCompCodeDropdown(temp_compCode)
      
    },[props.initCompCodeData])



    useEffect(()=>{
        if (props.selectedSearchField.includes('DOCUMENT_AMOUNT')){
            setShowDocumentCurrency(true)
        }else{
            setShowDocumentCurrency(false)
        }
    },[props.selectedSearchField])


    useEffect(()=>{
        let temp_list = [];

        for (let i=0; i < props.documentCurrencies.length; i++){
            temp_list.push([props.documentCurrencies[i],props.documentCurrencies[i]])
        }

        setDocumentCurrencies(temp_list)

    },[props.documentCurrencies])


  return (
        <div className='w-full'>
            <div className="flex justify-around mb-6">
                <SingleSelectDropdown setState={props.setSelectedVendorCustomer}  subTitle ={"Vendor or Customer"} dropDownSelections={vendorCustomer} defaultText={'Both'} defaultField={'BOTH'}/>
                <SingleSelectDropdown setState={props.setSelectedSearchField}  subTitle ={"Search Fields"} dropDownSelections={searchFields} defaultText={'Reference Number'} defaultField={'REF_DOC_NO'} />
            </div>

            <div className="flex justify-around mb-6">
                <MultiSelectDropdown setState={props.setSelectedSapSystems} subTitle ={"SAP System"} dropDownSelections={systemDropdown} defaultText={'All'} defaultField={[]}/>
                <MultiSelectDropdown setState={props.setSelectedCompCode}  subTitle ={"Company Codes"} dropDownSelections={compCodeDropdown} defaultText={'All'} defaultField={[]}/>
            </div>

            <div className="flex justify-around mb-6">

            <div className='max-w-[45%]'>
                    <DatePicker
                        className='border mt-6'
                        dateFormat="dd/MM/yyyy"
                        selected={props.startDate}
                        onChange={(date) => props.setStartDate(date)}
                    />

                    <div>
                        Date From <br/> (dd/mm/yyyy)
                    </div>

                </div>


                <div className='max-w-[45%]'>
                        <DatePicker
                            className='border mt-6'
                            dateFormat="dd/MM/yyyy"
                            selected={props.endDate}
                            onChange={(date) => props.setEndDate(date)}
                        />

                        <div>
                            Date To <br/> (dd/mm/yyyy)
                        </div>
                </div>
  


            </div>


        {showDocumentCurrency ? 
            <div>
                <div className="mb-6 ml-10 w-2/3">
                    <SingleSelectDropdown setState={props.setSelectedCurrency} subTitle ={"Document Currency (Optional)"} dropDownSelections={documentCurrencies} defaultText={'All'} defaultField={[]}/>
                </div>

    
                <div className='ml-10 mb-6 w-1/2'>
                    <div className='rounded-md p-1 border border-gray-300 shadow-md'>
                        <input className="w-full h-full px-4 py-3" ref={props.documentAmountRef} placeholder="Amount in document currency"/>
                    </div>
                    <div className='text opacity-75 text-left ml-4'>(US Format 553.53)</div>
                </div>

                <div className='ml-10 mb-6 w-1/2' >
                    <div className='rounded-md p-1 border border-gray-300 shadow-md'>
                        <input className="w-full h-full px-4 py-3" ref={props.tolleranceAmountRef} placeholder="Tollerance Amount"/>
                    </div>
                    <div className='text opacity-75 text-left ml-4'>Add tollerance amount +/-</div>
                </div>


    
            </div>

            :
            <></>
        }


        </div>
  )
}

