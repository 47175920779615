import React, { useState, useEffect } from 'react'
import { AxiosUpdateRequiredAres } from '../supportFunctions/axiosRequests'
import SuccessAlert from 'components/alert/SuccessAlert'
import WarningAlert from 'components/alert/WarningAlert'



export default function SelectRequiredAre(props) {

    const [selectedAre, setSelectedAre] = useState([])
    const [loading, setLoading] = useState(false)
    const [showSuccess,setShowSuccess] = useState(false)
    const [showWarning, setWarning] = useState(false)
    const [avalibleAre, setAvalibleAre] = useState([])

    const handleChange = (are, checked)=>{
        let tempSelectedAre =selectedAre
        if (checked){
          tempSelectedAre = tempSelectedAre.filter(i =>{if (i !== are){return true} return false})
        }else{
          tempSelectedAre.push(are)
        }
        setSelectedAre([...tempSelectedAre])
    }



    const handleSubmit = ()=>{
      setLoading(true)
      setShowSuccess(false)
      setWarning(false)

      AxiosUpdateRequiredAres(selectedAre).then(data =>{
        setLoading(false)
        if (data.error){
          setWarning(true)
          return
        }
        setShowSuccess(true)
      })
    }



    useEffect(()=>{
        if (props.data){
            setAvalibleAre([...props.data])
            let tempSelectedAreList = []
            props.data.forEach(i =>{
                if (i.selected){
                    tempSelectedAreList.push(i.are)
                }
            })
            setSelectedAre([...tempSelectedAreList])
        }
    },[props.data])


  return (
        <div className='w-1/2 flex flex-col justify-center mx-auto mt-6 shadow-md p-16'>
          <div className='w-1/2 mx-auto'>
            {showSuccess ? <SuccessAlert title={"Update successful"} /> : <></>}
            {showWarning ? <WarningAlert title={"Update error"} /> : <></>}
          </div>
          
            <div>
                <h4 className='text-xl font-semibold mb-6 text-center'>Select ARE&apos;s that should be included in OrgDB Hierarchy report</h4>

                <div className='max-h-[50vh] overflow-auto w-2/3 mx-auto' >
                <table className="min-w-full divide-y divide-gray-200 m-auto">
                            <thead className="bg-gray-200">
                                <tr>
                                    <th scope="col"className="px-6 py-3 text-left font-bold text-gray-500 tracking-wider">
                                        SAP System
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left font-bold text-gray-500 tracking-wider">
                                        ARE
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left font-bold text-gray-500 tracking-wider">
                                        ARE Selected
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {avalibleAre.map((i, index) => {
                                    let checked = selectedAre.includes(i.are)
                                    return(
                                    <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                        <td className="px-6 py-4 whitespace-nowrap font-semibold text-gray-900 text-left">{i.sapSystem}</td>
                                        <td className="px-6 py-4 whitespace-nowrap font-semibold text-gray-900 text-left">{i.are}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-center">
                                            <input className="text-purple-500 w-6 h-6 mr-2 focus:ring-purple-400 focus:ring-opacity-25 border border-gray-200 rounded text-left transition duration-500" 
                                            checked={checked} onChange={()=>handleChange(i.are, checked)} type="checkbox" value={ i.selected } />
                                        </td>
                                    </tr>
                                    )
                                }
                                )}
                            </tbody>
                    </table>
                </div>

                <div className='mt-6 flex justify-center'>
                  {loading  ? 
                    <button type="button" className="inline-flex cursor-wait items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                          Please Wait
                  </button>
                  
                :
                  <button
                  type="button"
                  className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={handleSubmit}
                  >Submit changes
                  </button>
                }

                </div>
            </div>

        
        </div>
  )
}

