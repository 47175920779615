import React from 'react'

export default function UserSelections(props) {

    const handleSearchTypeChange = (event) => {
        props.setSelectedAre(event.target.value);
      };

    const handleFromBounce = () =>{
        props.setFromError(false)  
        props.setSuccessMessage(false)
    }

    const handleToBounce = () =>{
        props.setToError(false)
        props.setSuccessMessage(false)
    }


    const areSelection = [
        'All'
        ,'404Q'
        ,'473Q'
        ,'475Q'
        ,'476Q'
        ,'477Q'
        ,'479Q'
        ,'480Q'
        ,'481Q'
        ,'482Q'
        ,'483Q'
        ,'484Q'
        ,'485Q'
        ]


  return (
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <div>
        <div className="mx-auto w-1/4">
            Select Are:
                <select
                    id="fy"
                    name="fy"
                    className={` text-lg mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md`}
                    defaultValue='What do you want to search'
                    onChange={handleSearchTypeChange}
                >
                    <option className="text-lg" disabled>Search Type</option>
                    {areSelection.map(i =>{
                    return <option key={i} className="text-lg">{i}</option>
                    })}
                </select>
            </div>


        <div className="flex mt-5">                    
            <div className={`${props.fromError ? 'text-red-600 animate-bounce' : 'bg-white text-gray-700'} text-lg font-medium text-center m-4"`}> 
                From: 
                    <div className="flex justify-left">
                            <div className="bg-white  sm:rounded-lg sm:px-10">
                                <div className="">

                                    <select
                                        id="fy"
                                        name="fy"
                                        className={` text-lg mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md`}
                                        defaultValue='From Gjahr'
                                        ref={props.fromYearRef}
                                        onClick={handleFromBounce}
                                    >
                                        <option className="text-lg" disabled>From Gjahr</option>
                                        {props.fromFiscalYear.map(i =>{
                                        return <option key={i} className="text-lg">{i}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        


                        <div className="flex justify-left">
                            <div className="bg-white sm:rounded-lg sm:px-10">
                            <div className="">

                                <select
                                    id="fp"
                                    name="fp"
                                    className="text-lg mt-1 block w-full pl-3 pr-10 py-2  border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    defaultValue='From Monat'
                                    ref={props.fromMonthRef}
                                    onClick={handleFromBounce}
                                >
                                    <option className="text-lg" disabled>From Monat</option>
                                    {props.fromFiscalPeriod.map(i =>{
                                    return <option key={i} className="text-lg">{i}</option>
                                    })}
                                </select>
                            </div>
                            </div>
                        </div>
            </div>
    

            <div className={`${props.toError ? 'text-red-600 animate-bounce' : 'bg-white text-gray-700'} text-lg font-medium text-center m-4"`}> 
                To:   
                    <div className="flex justify-left">
                            <div className="bg-white  sm:rounded-lg sm:px-10">
                                <div className="">

                                    <select
                                        id="fy"
                                        name="fy"
                                        className={` text-lg mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md`}
                                        defaultValue='To Gjahr'
                                        ref={props.toYearRef}
                                        onClick={handleToBounce}
                                    >
                                        <option className="text-lg" disabled>To Gjahr</option>
                                        {props.toFiscalYear.map(i =>{
                                        return <option key={i} className="text-lg">{i}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        


                        <div className="flex justify-left">
                            <div className="bg-white sm:rounded-lg sm:px-10">
                            <div className="">

                                <select
                                    id="fp"
                                    name="fp"
                                    className="text-lg mt-1 block w-full pl-3 pr-10 py-2  border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    defaultValue='To Monat'
                                    ref={props.toMonthRef}
                                    onClick={handleToBounce}
                                >
                                    <option className="text-lg" disabled>To Monat</option>
                                    {props.toFiscalPeriod.map(i =>{
                                    return <option key={i} className="text-lg">{i}</option>
                                    })}
                                </select>
                            </div>
                            </div>
                        </div>
                </div>
    
            </div>

    
    </div>


    </div>
  )
}
