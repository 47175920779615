import React from 'react'
import { ArrowCircleUpIcon } from '@heroicons/react/outline';
import * as XLSX from 'xlsx'

export default function UploadSearch(props) {
    const uploadExcelData = async (e) =>{
        const file = e.target.files[0]
        const data = await file.arrayBuffer();
        const wb = XLSX.read(data);
        const ws = wb.Sheets[wb.SheetNames[0]]

        const defaultColumnNames = [["sapSystem","mandt","companyCode","vendorNumber"]]
        XLSX.utils.sheet_add_aoa(ws, defaultColumnNames, {origin: "A1"})


        props.setUploadedData(XLSX.utils.sheet_to_json(ws))
    }

  return (
    <div className="flex mt-6 items-center justify-center bg-grey-lighter">
    <label className="lg:w-64 md:w-24 flex flex-col items-center px-4 py-6 bg-white text-blue-500 rounded-lg shadow-lg tracking-wide uppercase border border-blue-500 cursor-pointer hover:bg-blue-300 hover:text-white">
    <ArrowCircleUpIcon className='h-8 w-8' />
        <span className="mt-2 text-base leading-normal text-center">Upload searchfile</span>
        <input onChange={uploadExcelData} type='file' className="hidden" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
    </label>
</div>
  )
}
