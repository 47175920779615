import React from 'react'

export default function LoadingSpinner() {
    return (
    
    <div className="flex justify-center items-center">
        <div className="animate-spin rounded-full h-48 w-48 mt-16 border-t-2 border-b-2 border-purple-500"></div>
    </div>

    )
}
