import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';

export const axios_cash_alert = async  (year, month ) =>{
    let data = {
    'year' : year,
    'period' : month,
    } 
    
    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'gl_dashboard/cash_alert/',
            headers: { 'content-type': 'application/json' },
            params : data,
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
      
    }
}
