import React, {useRef, useState, useEffect} from "react";
import Navbar from "components/navigation/navbar/Navbar";
import LoadingSpinner from "components/loading/LoadingSpinner";
import {axios_wht_report } from 'pages/taxDashboard/pages/wht/support_functions/axiosRequests'
//import { exportToExcel } from './support_functions/exportToExcel'
import SuccessAlert from "components/alert/SuccessAlert";
import WarningAlert from "components/alert/WarningAlert";
import GenericFooter from "components/navigation/footer/GenericFooter";
export default function WhtReport(){


    const fromYearRef = useRef()
    const fromMonthRef = useRef()
    const toYearRef = useRef()
    const toMonthRef = useRef()

    const [fromError, setFromError] = useState(false)
    const [toError, setToError] = useState(false)
    
    
    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    //from and to dropdowns
    const [fromFiscalYear, setFromFiscalYear] = useState([])
    const [fromFiscalPeriod, setFromFiscalPeriod] = useState([])
    const [toFiscalYear, setToFiscalYear] = useState([])
    const [toFiscalPeriod, setToFiscalPeriod] = useState([])

    const handleFromBounce = () =>{
        setFromError(false)  
        setSuccessMessage(false)
    }

    const handleToBounce = () =>{
        setToError(false)
        setSuccessMessage(false)
    }

    const handleSubmit = async () =>{
        let error = false
        setLoading(false)
        setSuccessMessage(false)

        //check to make sure correct year and month are selected 
        //this is just makes sure they actually selected somethin
        if (!fromFiscalYear.includes(fromYearRef.current.value)){
            setFromError(true)
            error = true
        }

        if (!fromFiscalPeriod.includes(fromMonthRef.current.value)){
            setFromError(true)
            error = true
        }




        if (!toFiscalYear.includes(toYearRef.current.value)){
            setToError(true)
            error = true
        }
        
        if (!toFiscalPeriod.includes(toMonthRef.current.value)){
            setToError(true)
            error = true
        }

        if (error === false){
            setLoading(true)
                axios_wht_report(fromYearRef.current.value, fromMonthRef.current.value, toYearRef.current.value, toMonthRef.current.value).then( data =>{
                    setLoading(false)
                    if (data.error){
                        setErrorMessage(true)
                        return
                    }
                    window.open(data.data.url, "_blank")
                    setSuccessMessage(true)
                    fromYearRef.current.value = 'From Gjahr'
                    fromMonthRef.current.value = 'From Monat'
                    toYearRef.current.value = 'To Gjahr'
                    toMonthRef.current.value = 'To Monat'
                    return
                })
        }else{
            return null
            
        }
    }


    // assign the correct values to the dropdown
    useEffect(() =>{

        let date = new Date()

        if (date.getMonth() >= 9){
            setFromFiscalYear([(date.getFullYear() + 1 ).toString(), date.getFullYear().toString(), (date.getFullYear() - 1).toString()])
            setToFiscalYear([(date.getFullYear() + 1 ).toString(), date.getFullYear().toString(), (date.getFullYear() - 1).toString()])
        }else{
            setFromFiscalYear([date.getFullYear().toString(), (date.getFullYear() - 1).toString()])
            setToFiscalYear([date.getFullYear().toString(), (date.getFullYear() - 1).toString()])
        }

        setFromFiscalPeriod(['1','2','3','4','5','6','7','8','9','10','11','12','13-16'])
        setToFiscalPeriod(['1','2','3','4','5','6','7','8','9','10','11','12', '13-16'])

    },[])




    return (
        <div>

            <div>
                <Navbar activePage="/tax_dashboard"/>
            </div>


            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md mt-16">
                    <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">WHT Report</h1>
                </div>
                
                {successMessage ?<div className="w-1/4 m-auto"><SuccessAlert title={'Export complete'} /></div>: <></>}

                {errorMessage ?<div className="w-1/4 m-auto"><WarningAlert title={'There was an error'} subtitle={'Contact Bernd or Kevin'} /></div>: <></>}

                <div className="mt-8 sm:mx-auto shadow-md sm:w-full sm:max-w-md">
                    <div className="flex mt-5">
                    

        
                        <div className={`${fromError ? 'text-red-600 animate-bounce' : 'bg-white text-gray-700'} text-lg font-medium text-center m-4"`}> 
                            From: 
                                <div className="flex justify-left">
                                        <div className="bg-white  sm:rounded-lg sm:px-10">
                                            <div className="">

                                                <select
                                                    id="fy"
                                                    name="fy"
                                                    className={` text-lg mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md`}
                                                    defaultValue='From Gjahr'
                                                    ref={fromYearRef}
                                                    onClick={handleFromBounce}
                                                >
                                                    <option className="text-lg" disabled>From Gjahr</option>
                                                    {fromFiscalYear.map(i =>{
                                                    return <option key={i} className="text-lg">{i}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    


                                    <div className="flex justify-left">
                                        <div className="bg-white sm:rounded-lg sm:px-10">
                                        <div className="">
        
                                            <select
                                                id="fp"
                                                name="fp"
                                                className="text-lg mt-1 block w-full pl-3 pr-10 py-2  border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                                defaultValue='From Monat'
                                                ref={fromMonthRef}
                                                onClick={handleFromBounce}
                                            >
                                                <option className="text-lg" disabled>From Monat</option>
                                                {fromFiscalPeriod.map(i =>{
                                                return <option key={i} className="text-lg">{i}</option>
                                                })}
                                            </select>
                                        </div>
                                        </div>
                                    </div>
                        </div>
                

                        <div className={`${toError ? 'text-red-600 animate-bounce' : 'bg-white text-gray-700'} text-lg font-medium text-center m-4"`}> 
                            To:   
                                <div className="flex justify-left">
                                        <div className="bg-white  sm:rounded-lg sm:px-10">
                                            <div className="">

                                                <select
                                                    id="fy"
                                                    name="fy"
                                                    className={` text-lg mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md`}
                                                    defaultValue='To Gjahr'
                                                    ref={toYearRef}
                                                    onClick={handleToBounce}
                                                >
                                                    <option className="text-lg" disabled>To Gjahr</option>
                                                    {toFiscalYear.map(i =>{
                                                    return <option key={i} className="text-lg">{i}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    


                                    <div className="flex justify-left">
                                        <div className="bg-white sm:rounded-lg sm:px-10">
                                        <div className="">
        
                                            <select
                                                id="fp"
                                                name="fp"
                                                className="text-lg mt-1 block w-full pl-3 pr-10 py-2  border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                                defaultValue='To Monat'
                                                ref={toMonthRef}
                                                onClick={handleToBounce}
                                            >
                                                <option className="text-lg" disabled>To Monat</option>
                                                {toFiscalPeriod.map(i =>{
                                                return <option key={i} className="text-lg">{i}</option>
                                                })}
                                            </select>
                                        </div>
                                        </div>
                                    </div>
                        </div>
                




                    </div>

                    <div className="mb-12 mt-5 flex justify-center m-auto">
                    {loading ? 
                                            <button
                            type="button"
                            className="px-4 py-2 border cursor-progress border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200"
                            disabled
                        >
                            Please Wait
                        </button>
                    
                    :
                        <button
                            type="button"
                            className="px-4 py-2 border cursor-pointer border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={handleSubmit}
                        >
                            Generate report
                        </button>

                    }
                    </div>
                </div>

            </div>

            <div>
                {loading ? 
                    <LoadingSpinner />

                    :
                    <></>
                }
            </div>


            <div>
                <GenericFooter />
            </div>
      </div>
    )
}


