import React, {useState, useEffect} from "react";
import Navbar from "components/navigation/navbar/Navbar";
import LoadingSpinner from "components/loading/LoadingSpinner";
import {axios_open_invoice_companyCodes, axios_open_invoice_scheduler,axios_open_invoice_poll ,downloadJsonContents } from 'pages/apDashboard/pages/openInvoice/support_functions/axiosRequest'
import SuccessAlert from "components/alert/SuccessAlert";
import WarningAlert from "components/alert/WarningAlert";
import ReportTable from 'components/reportTable/reportTable'
import UserMenu from 'pages/apDashboard/pages/openInvoice/components/userMenu'
import InputField from 'pages/apDashboard/pages/openInvoice/components/inputField'
import GenerticFooter from 'components/navigation/footer/GenericFooter'
import { useSelector } from 'react-redux'


export default function OpenInvoice(){


    const user = useSelector(state => state.user)
 
    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [warningMessage, setWarningMessage] = useState('')
    const [showWarningMessage, setShowWarningMessage] = useState(false)
    const [showDataTable, setShowDataTable] = useState(false)
    const [tableData, setTableData] = useState([])
    //const defaultColumnHeaders = ["SAP System",'Vendor number','Vendor Name','Vendor Orgid', 'EDI Orgid','IFA','EDI Address','Mandt','Company Code', 'Reference Number','Cockpit or bseg BELNR','Profit Center','Depth Structure','GL Indicator (UMSKZ)','PO Number','Document Date (BLDAT)','Posting Date (BUDAT)','Fiscal Year', 'Gross Amount','Discount','Gross amount - Discount','Currency','Baseline Date (ZFBDT)','Cleared','Payment Block','Reversal','Clearing Document','Clearing Date','Debit/Credit','Day1','Discount1','Day2','Discount2', 'Net Due','Posting Status','Payment Date','Trading Partner (VBUND)']
    const [searchInput, setSearchInput]= useState('')

    const [selectedPaymentBlockStatus, setSelectedPaymentBlockStatus]= useState([])
    const [initCompCodeData, setInitCompCodeData]= useState([])
  
    const [selectedInvoiceStatus, setSelectedInvoiceStatus] = useState([])
    const [selectedSapSystems, setSelectedSapSystems] = useState([])
    const [selectedSearchField, setSelectedSearchField] = useState([])
    const [selectedCompCode, setSelectedCompCode] = useState([])
    const [selectedClearedStatus, setSelectedClearedStatus] = useState([])
    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date(new Date().setFullYear( new Date().getFullYear() - 7)));
    const[ fileUrl, setFileUrl] = useState()
    const[ jsonTableUrl, setJsonTableUrl] = useState()
    const[ processUUID, setProcessUUID] = useState()


    //export the data to excel 
    const exportDataToExcel = () =>{
        window.open(fileUrl, "_blank")
    }

    const startPolling = (uuid, pollCounter)=>{
        if (pollCounter === 14){
            setErrorMessage(true)
            setLoading(false)
            clearTimeout(startPolling)
            return 
        }
            axios_open_invoice_poll(uuid).then(data =>{
                if (data.error){
                    setErrorMessage(true)
                    setLoading(false)
                    return
                }
                if (!data.data.processing){
                    setFileUrl(data.data.fileUrl)
                    setJsonTableUrl(data.data.jsonUrl)
                    clearTimeout(startPolling)
                    return
                }
                setTimeout(()=>startPolling(uuid,pollCounter+1), 5000);
            })
    }


    //handle the submit button
    const handleSubmit = async () =>{
        let error = false
        setLoading(false)
        setSuccessMessage(false)
        setShowDataTable(false)

        if (searchInput === '' &&  selectedInvoiceStatus.length === 0 && selectedPaymentBlockStatus.length === 0){
            error = true
            setWarningMessage('You forgot to search for something')
            setShowWarningMessage(true)
        }else{
            setShowWarningMessage(false)
        }



        if (error === false){
            setLoading(true)
            axios_open_invoice_scheduler(selectedSapSystems, selectedSearchField, selectedCompCode, selectedClearedStatus, searchInput,startDate, endDate, selectedInvoiceStatus,selectedPaymentBlockStatus).then( data =>{
                if (data.error){
                    setErrorMessage(true)
                    setLoading(false)
                    return
                }
                setProcessUUID(data.data.uuid)
                return
            })
        }else{
            return null
            
        }
    }



    // wait until the table data is updated in use state before shwoing table
    useEffect(() =>{
        if (tableData.length !== 0){
            setLoading(false)        
            setShowDataTable(true)
            return
        }
    },[tableData])

    useEffect(()=>{
        if(processUUID){
            startPolling(processUUID, 0)
        }
    },[processUUID])


    // retrieve the company code data
    useEffect(() =>{
        // this will serve as the company code/sap system company code 'source of truth'
        axios_open_invoice_companyCodes().then(data =>{
            setInitCompCodeData(data.data.data)
        })
    },[])

    // when the jsonURl value is updated go get the data
    useEffect(()=>{
        if (jsonTableUrl){
            downloadJsonContents(jsonTableUrl).then(data =>{
                setTableData(data)
            })
        }
    },[jsonTableUrl])



    return (
        <div>

            <div>
                <Navbar activePage="/ap_dashboard"/>
            </div>


            <div className="flex flex-col justify-center py-8 sm:px-6 lg:px-8 ">

                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <h1 className="mt-4 text-center text-3xl font-extrabold text-gray-900">Open Invoice Report</h1>
                </div>
                
                {successMessage ?<div className="w-1/4 m-auto"><SuccessAlert title={'Export complete'} /></div>: <></>}
                {errorMessage ?<div className="w-1/4 m-auto"><WarningAlert title={'There was an error'} subtitle={'Contact Bernd or Kevin'} /></div>: <></>}
                {showWarningMessage ?<div className="w-1/4 m-auto"><WarningAlert title={warningMessage} subtitle={''} /></div>: <></>}



            
                <div className="min-w-[33%] mt-8 shadow-md sm:w-full sm:max-w-md sm:mx-auto">

                <div className="mt-4 mb-6">
                    <UserMenu  setSelectedSapSystems={setSelectedSapSystems} setSelectedSearchField={setSelectedSearchField} setSelectedCompCode={setSelectedCompCode}
                        setSelectedClearedStatus={setSelectedClearedStatus} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} 
                        initCompCodeData={initCompCodeData} setSelectedInvoiceStatus={setSelectedInvoiceStatus} user={user} setSelectedPaymentBlockStatus={setSelectedPaymentBlockStatus}
                        />
                </div>

                

                <div className="mt-4 mb-6 flex justify-center">
                    <InputField setSearchInput={setSearchInput} />
                    
                </div>

                    <div className="mb-4 flex justify-center m-auto">
                    {loading ? 
                                            <button
                            type="button"
                            className="px-4 py-2 border cursor-progress border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200"
                            disabled
                        >
                            Please Wait
                        </button>
                    
                    :
                        <button
                            type="button"
                            className="px-4 py-2 border cursor-pointer border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={handleSubmit}
                        >
                            Search
                        </button>

                    }
                    </div>
                </div>

            </div>

            <div>
                {loading ? 
                    <LoadingSpinner />

                    :
                    <></>
                }
            </div>


            {showDataTable ? 
                <div className="w-2/3 m-auto mb-24">
                          <button
                                type="button"
                                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={exportDataToExcel}
                            > Export to excel</button>



                   {/* <ReportTable data={tableData} columnHeaders={defaultColumnHeaders}  /> */}

                    <ReportTable data={tableData} />
                </div>

                :
                <></>
            }


            <div>
                <GenerticFooter />
            </div>
      </div>
    )
}


