import React,{ Fragment, useState,useEffect } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'



export default function GenericDropdown(props) {

    const [dropdownSelection, setDropdownSelection] = useState()


    const handleUserChange = (system)=>{
        props.setSelectedDropdownSelection(system)
        setDropdownSelection(system)
    }

    useEffect(()=>{
      setDropdownSelection(props.defaultText)
    },[props.defaultText])


  return (
    <Menu as="div" className="relative inline-block text-left max-h-24 w-full">
      <div>
        <Menu.Button className="inline-flex w-full justify-between rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
         {dropdownSelection}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">

            {props.dropdownSelections.map((i, k) => {
                return(
                    <Menu.Item key={k}>
                        <div className={'text-gray-700 text-center rounded-md block px-4 py-2 text-sm hover:bg-gray-200 hover:text-gray-700'}
                        onClick={()=>handleUserChange(i)}
                        >
                            {i}
                        </div>
                      </Menu.Item>
                )
            })
        }

          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
