
import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';

export const axios_wht_report = async  (fromYear, fromMonth, toYear, toMonth ) =>{

        //when the user selects 13-16 then the backend needs to see period 16
        if (fromMonth === '13-16'){
            fromMonth = '13'
        }

        if (toMonth === '13-16'){
            toMonth = '16'
        }



    let data = {
    'fromGjahr' : fromYear,
    'fromMonat' : fromMonth,
    'toGjahr' : toYear,
    'toMonat' : toMonth,
    } 

    
    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'tax_dashboard/wht_report/',
            headers: { 'content-type': 'application/json' },
            params : data,
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}


