import React from 'react'

export default function UserSelections(props) {

    const handleSearchTypeChange = (event) => {
        props.setSelectedAre(event.target.value);
      };



    const areSelection = [
        '404Q'
        ,'473Q'
        ,'475Q'
        ,'476Q'
        ,'477Q'
        ,'479Q'
        ,'480Q'
        ,'481Q'
        ,'482Q'
        ,'483Q'
        ,'484Q'
        ,'485Q'
        ]


  return (
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <div>
        <div className="mx-auto w-1/4">
            Select Are:
                <select
                    id="fy"
                    name="fy"
                    className={` text-lg mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md`}
                    defaultValue='What do you want to search'
                    onChange={handleSearchTypeChange}
                >
                    <option className="text-lg" disabled>Search Type</option>
                    {areSelection.map(i =>{
                    return <option key={i} className="text-lg">{i}</option>
                    })}
                </select>
            </div>



    
    </div>


    </div>
  )
}
