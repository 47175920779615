import React, {useRef, useState, useEffect} from "react";
import Navbar from "components/navigation/navbar/Navbar";
import LoadingSpinner from "components/loading/LoadingSpinner";
import {axios_crisk,axios_crisk_get_dropdowns } from  'pages/glDashboard/pages/crisk/support_functions/axios_crisk'
import SuccessAlert from "components/alert/SuccessAlert";
import WarningAlert from "components/alert/WarningAlert";
import GenericFooter from "components/navigation/footer/GenericFooter";



export default function Crisk() {

    const areRef = useRef()
    const [areError, setAreError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [are, setAre] = useState("")
    const [sapSystem, setSapSystem] = useState("")
    const [areSelection, setAreSelections] = useState([{are:"Make selection", sapSystem: ""}])
    const [fiscalYear, setFiscalYear] = useState([])
    const [fiscalPeriod, setFiscalPeriod] = useState([])
    const [selectedYear, setSelectedYear] = useState()
    const [selectedPeriod, setSelectedPeriod] = useState()


    const yearRef = useRef()
    const monthRef = useRef()


    const handleYearSelections=(period)=>{
        
        if (period === 'year'){
            setSelectedYear(yearRef.current.value)
        }else{
            setSelectedPeriod(monthRef.current.value)
        }

    }



    const handleSubmit = ()=>{
        if (are === "" || sapSystem === ""){
            setAreError(true)
            return
        }


        setLoading(true)
        setErrorMessage(false)
        axios_crisk(are, sapSystem, selectedYear, selectedPeriod ).then(data =>{
            setLoading(false)
            if (data.error){                
                setErrorMessage(true)
                return
            }
            window.open(data.data.fileUrl, "_blank")
            setSuccessMessage(true) 
        })

    }

    const handleClick = () =>{
        setSuccessMessage(false)
        setErrorMessage(false)
        setAreError(false)
        const selectionArray = areRef.current.value.split(":")
        setSapSystem(selectionArray[0])
        setAre(selectionArray[1])
    }


    useEffect(()=>{
        axios_crisk_get_dropdowns().then( data =>{
            setAreSelections([...areSelection,...data.data.data])

        })

        let date = new Date()

        if (date.getMonth() >= 9){
            setSelectedYear(date.getFullYear() + 1)
            setFiscalYear([(date.getFullYear() + 1 ).toString(), date.getFullYear().toString(), (date.getFullYear() - 1).toString()])
        }else{
            setSelectedYear(date.getFullYear())
            setFiscalYear([date.getFullYear().toString(), (date.getFullYear() - 1).toString()])
        }

        setFiscalPeriod(['1','2','3','4','5','6','7','8','9','10','11','12'])
        setSelectedPeriod('12')
    },[])
    
    return (
        <div>

            <div>
                <Navbar activePage="/gl_dashboard"/>
            </div>


            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md mt-16">
                    <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">CRisk Report</h1>
                </div>
                
                {successMessage ?<div className="w-1/4 m-auto"><SuccessAlert title={'Export complete'} /></div>: <></>}

                {errorMessage ?<div className="w-1/4 m-auto"><WarningAlert title={'There was an error'} subtitle={'Contact Bernd or Kevin'} /></div>: <></>}

                <div className="mt-8 sm:mx-auto shadow sm:w-full sm:max-w-md">
                    <div className="flex flex-col justify-center">


                        <div className="bg-white py-4 px-4 sm:rounded-lg sm:px-10 w-1/2 mx-auto">
                            <div className="">
                                <label htmlFor="fp" className={`${areError ? 'text-red-600 animate-bounce' : 'bg-white text-gray-700'} block text-lg text-center font-medium border-b-2"`} >
                                   Select ARE
                                </label>
                                <select
                                    id="fp"
                                    name="fp"
                                    className="text-lg mt-1 block w-full pl-3 pr-10 py-2  border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    defaultValue='Are'
                                    ref={areRef}
                                    onChange={handleClick}
                                >

                                    {areSelection.map(i =>{
                                    return <option key={i} value={i.sapSystem +":"+i.are} className="text-lg">{i.are}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="text-lg text-gray-700 text-center mt-2 mb-2">Bank Balance ending period</div>

                    <div className="flex justify-center mb-8">
                        <div className="bg-white px-4 sm:rounded-lg sm:px-10">
                            <div className="">
                                <label htmlFor="fy" className={`bg-white text-gray-700 font-medium block text-lg`}>
                                    Fiscal Year
                                </label>
                                <select
                                    id="fy"
                                    name="fy"
                                    className="text-lg mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    onChange={()=>handleYearSelections('year')}
                                    ref={yearRef}
                                >
                           
                                    {fiscalYear.map(i =>{
                                    return <option key={i} className="text-lg">{i}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="bg-white px-4 sm:rounded-lg sm:px-10">
                            <div className="">
                                <label htmlFor="fp" className={`bg-white text-gray-700 block text-lg font-medium`} >
                                    Fiscal Period
                                </label>
                                <select
                                    id="fp"
                                    name="fp"
                                    className="text-lg mt-1 block w-full pl-3 pr-10 py-2  border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    onChange={()=>handleYearSelections('period')}
                                    ref={monthRef}
                                >
                     
                                    {fiscalPeriod.map(i =>{
                                    return <option key={i} className="text-lg">{i}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                        

                    </div>

                    <div className="mb-12 flex justify-center m-auto">
                    {loading ? 
                                            <button
                            type="button"
                            className="px-4 py-2 border cursor-progress border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200"
                            disabled
                        >
                            Please Wait
                        </button>
                    
                    :
                        <button
                            type="button"
                            className="px-4 py-2 border cursor-pointer border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={handleSubmit}
                        >
                            Generate report
                        </button>

                    }
                    </div>
                </div>


                

                </div>

            </div>

            <div>
                {loading ? 
                    <LoadingSpinner />

                    :
                    <></>
                }
            </div>

            <div>
                <GenericFooter />
            </div>
      </div>
    
  )
}
