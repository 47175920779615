import React, { useEffect, useState } from 'react'
import Navbar from 'components/navigation/navbar/Navbar'
import GenerticFooter from 'components/navigation/footer/GenericFooter'
import HeroSection from './components/HeroSection'
import ContactSection from './components/ContactSection'
import FeatureSections from './components/FeatureSections'
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
import WarningAlert from 'components/alert/WarningAlert'

export default function LandingPage() {
    const location = useLocation()
    const [errorMessages, setErrorMessages] = useState(null)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const user = useSelector(state => state.user)

    useEffect(() =>{

        if (location.state !== undefined){

            if (location.state.permissionIssue){
                setErrorMessages("You do not have required permissions for this page.")
                setShowErrorMessage(true)
            }
        }
       
    },[location])




    return (
        <div>
            <Navbar activePage="/" permissions={user.permissions}/>

            {showErrorMessage ?
                <div className='w-1/3 mt-6 m-auto'>
                    <WarningAlert title={errorMessages} subtitle={"Please send us a message below or contact Bernd or Kevin"}/>
                </div>
                :
                <></>
            }

            <div>
                <HeroSection />
            </div>

            <div>
                <FeatureSections />
            </div>

            <div>
                <ContactSection user={user.user} />
            </div>


            <div>
                <GenerticFooter />
            </div>

        </div>
    )
}
