import React, {useState, useEffect} from 'react'
import Navbar from 'components/navigation/navbar/Navbar'
import GenericFooter from 'components/navigation/footer/GenericFooter'
import DropdownSelection from './components/mainDropdownMenu'
import UploadNewComponentList from './components/uploadNewComponentList'
import LoadingSpinner from 'components/loading/LoadingSpinner'
import ViewComponentScanList from './components/viewComponentScanList'
import RunITComponentScan from './components/runITComponentScan'
import SuccessAlert from 'components/alert/SuccessAlert'
import ReportTable from 'components/reportTable/reportTable'
import { downloadJsonContents } from './supportFunctions/axiosRequests'

export default function ItComponentScan() {
  const [selectedDropdown, setSelectedDropdown] = useState("")
  const [showLoading, setShowLoading] = useState(false)
  const [itComponentScanData, setItComponentScanData] = useState([])
  const [showDataTable, setShowDataTable] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const[ fileUrl, setFileUrl] = useState()
  const[ jsonTableUrl, setJsonTableUrl] = useState()

  const defaultSystems = ['AP801', 'AP901', 'BR101', 'E3501', 'FNP01', 'GPP01', 'KSP01', 'MPP01', 'OPP01', 'P5E01', 'PD201', 'PS102', 'PT201', 'PTA02', 'PTC01', 'PTF01', 'PTG01', 'PTI01', 'REP01', 'VP101', 'VP201']
  


  const exportToExcel = () =>{
    window.open(fileUrl, "_blank")
    setShowSuccess(true)
  }

      // when the jsonURl value is updated go get the data
      useEffect(()=>{
        if (jsonTableUrl){
            downloadJsonContents(jsonTableUrl).then(data =>{
                setItComponentScanData(data)
                setShowLoading(false)
                setShowDataTable(true)
            })
        }
    },[jsonTableUrl])


    useEffect(()=>{
      if (selectedDropdown !=='runITComponentScan' ){
        setShowDataTable(false)
      }
    },[selectedDropdown])


  return (
    <div>
      <Navbar activePage="/ac_operations"/>

      <div className='mt-20 shadow-lg w-1/2 mx-auto p-20 rounded-md'>
  
        <div className='text-3xl font-semibold mx-auto'>
                <h2 className='text-center'>IT Component Scan Report</h2>
          </div>  

          <div className='mt-6 w-2/3 min-w-1/2 mx-auto flex justify-center'>
                    <DropdownSelection setSelectedReportName={setSelectedDropdown}/>
          </div>


          {selectedDropdown === 'uploadComponentInput' ? <UploadNewComponentList defaultSystems={defaultSystems} setShowLoading={setShowLoading}  /> :<></>}
          {selectedDropdown === 'viewComponentScan' ? <ViewComponentScanList setShowLoading={setShowLoading}  /> :<></>}
          {selectedDropdown === 'runITComponentScan' ? <RunITComponentScan showLoading={showLoading} setShowSuccess={setShowSuccess} setShowLoading={setShowLoading} setShowDataTable={setShowDataTable} setItComponentScanData={setItComponentScanData} setFileUrl={setFileUrl} setJsonTableUrl={setJsonTableUrl} /> :<></>}
      
      
      
      </div>

    <div>
      {showLoading ?
      <LoadingSpinner />
      :
      <></>  
      }
    </div>


    <div className='mt-6 min-w-1/2 flex-col'>

      <div className='flex justify-center mb-4'>
        {showSuccess ? <SuccessAlert title={"Export Successful"}/> : <></>}
      </div>


      {showDataTable ?
      <div className='flex-col mt-6 '>
        <div className='flex justify-center mt-6'> 
   
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-sm font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={exportToExcel}
          >
            Export to excel
          </button>
        </div>

        <div className='flex justify-center mt-6'> 
          <ReportTable data={itComponentScanData} />  
        </div>
        
    </div>
    :
    <></>
  }



    </div>



  <GenericFooter />

    </div>
  )
}

