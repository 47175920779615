
import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';

export const axios_psp_structure_report = async  () =>{

    
    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'fx_dashboard/psp_structure/',
            headers: { 'content-type': 'application/json' },
            withCredentials: true,
        });
        
        if (response.status === 200){

            return {data : response.data, error: false}
        }else{
            return {data : [], error: true}
        }

    }catch(e){
        return {data : [], error: true}

    }
}


