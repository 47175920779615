
import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';

const convertDate = (datepickerValue) =>{
    let year = datepickerValue.getFullYear()
    let month = datepickerValue.getMonth() + 1
    let day = datepickerValue.getDate()

    let formattedString = day + "/" + month + "/" + year 
    return (formattedString)
}

export const axios_cash_allocation = async (selectedVendorCustomer, selectedSearchField,selectedSapSystems, selectedCompCode, startDate, endDate, searchInput, selectedCurrency, documentAmountRef, tolleranceAmountRef) =>{

    let params  = new URLSearchParams() 

    console.log(documentAmountRef)

    params.append('reportName' , selectedVendorCustomer[0])
    params.append('searchInput' , searchInput)
    params.append('searchField' , selectedSearchField[0])
    if (selectedCurrency[0]){
        params.append('searchCurrency' , selectedCurrency[0]) 
    }
    params.append('startDate' , convertDate(startDate))
    params.append('endDate' , convertDate(endDate))
    if (documentAmountRef){
        params.append('searchDocumentAmount' , documentAmountRef)
    }
    if (tolleranceAmountRef){
        params.append('searchTollerance' , tolleranceAmountRef)
    }

    //params.append('selectedSystems', selectedSapSystems)
    //params.append('searchCompCode' , selectedCompCode)

    selectedSapSystems.forEach(element => {
        params.append('selectedSystems', element)
    });

    selectedCompCode.forEach(element => {
        params.append('searchCompCode', element)
    });


    let body = {
        'searchCompCode' : selectedCompCode,
        'selectedSystems' : selectedSapSystems,
        'searchTollerance' : tolleranceAmountRef,
        'searchDocumentAmount' : documentAmountRef,
        'endDate' : convertDate(endDate),
        'startDate' : convertDate(startDate),
        'searchField' : selectedSearchField[0],
        'searchInput' : searchInput,
        'reportName' : selectedVendorCustomer[0],
    }


    try{
        const response = await axios({
            method: 'POST',
            url: sapDashboardAPIBaseuri + 'ap_dashboard/cash_allocation/',
            headers: { 'content-type': 'application/json' },
            data : body,
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}


export const axios_cash_allocation_support = async (requestType) =>{

    let requestParms = {}

    if (requestType){
        requestParms["dataType"] = "companyCodes"
    }else{
        requestParms["dataType"] = "documentCurrencies"
    }

    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'ap_dashboard/cash_allocation/support/',
            headers: { 'content-type': 'application/json' },
            params : requestParms,
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}



export const downloadJsonContents = async (urlPath) =>{
    const response = await axios({
        method: 'GET',
        url: urlPath,
        headers: { 'content-type': 'application/json' },
        responseType: 'application/json',
        transformResponse: (data) => JSON.parse(data),
    })
    if (response.status === 200){
        return response.data
    }
}
