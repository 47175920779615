
import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';

export const axios_vendorVolumeSearch = async  (fromYear, fromMonth, toYear, toMonth, data ) =>{

        //when the user selects 13-16 then the backend needs to see period 16
        if (fromMonth === '13-16'){
            fromMonth = '13'
        }

        if (toMonth === '13-16'){
            toMonth = '16'
        }

        for (let i = 0; i < data.length; i++){
            data[i].vendorNumber = String(data[i].vendorNumber)
            data[i].companyCode = String(data[i].companyCode)
            data[i].mandt = String(data[i].mandt)
            data[i].sapSystem = String(data[i].sapSystem)
        }



    let postBody = {
    'yearFrom' : fromYear,
    'periodFrom' : fromMonth,
    'yearTo' : toYear,
    'periodTo' : toMonth,
    'data' : data,
    } 

    
    try{
        const response = await axios({
            method: 'POST',
            url: sapDashboardAPIBaseuri + 'ac_operations/vendor_volume_search/',
            headers: { 'content-type': 'application/json' },
            data : postBody,
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}


export const axios_poll =async (uuid) =>{
    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'ac_operations/vendor_volume_search/poll/',
            headers: { 'content-type': 'application/json' },
            params: {processUUID : uuid},
            withCredentials: true,
        });
        
        if (response.status === 200 ){
            return {data: response.data, error : false}
            
        }else{
            return {data: response.data, error : false}
        }

    }catch(e){
        return {data: [], error : true}
    }
}

export const downloadJsonContents = async (urlPath) =>{
    const response = await axios({
        method: 'GET',
        url: urlPath,
        headers: { 'content-type': 'application/json' },
        responseType: 'application/json',
        transformResponse: (data) => JSON.parse(data),
    })
    if (response.status === 200){
        return response.data
    }
}