import React, {useState} from 'react'
import { axios_global_open_item } from '../support_functions.js/axiosRequest'
export default function UserMenu(props) {
    const AreMapping = ['4413','4659','4678','5210','5510','5539','404Q','426F','429F','438X','483Q','462X','463X','465X','471X','473Q','473X','474S','476E','476Q','477Q','477X','479Q','480Q','482Q','482X','485E','486X','488X','4PC9','510S','519X','539E','552N','553N','561P','563P','583E']
    const defaultText = "Select ARE" 
    const [selectedARE, setSelectedARE] = useState(null)
    const [selectionWarning, setSelectionWarning] = useState(false)

    function updateSelected(e){
        if (e.target.value){
            if (e.target.value == defaultText){
                setSelectedARE(null)
                return
            }
            setSelectedARE(e.target.value)
            setSelectionWarning(false)
        }else{
            setSelectedARE(null)
        }
        
    }

    const handleSubmit = () =>{
        setSelectionWarning(false)
        props.setErrorMessage(false)
        props.setSuccessMessage(false)
       
        if(selectedARE){
            props.setLoading(true)
            axios_global_open_item(selectedARE).then(data =>{
                if (data.error){
                    props.setErrorMessage(true)
                    props.setLoading(false)
                    return
                }
                props.setFileURL(data.data.fileUrl)
                props.setLoading(false)
                return
            })
        }else{
            setSelectionWarning(true)
        }
       

    }
  
    return (

    <div className="min-w-[33%] mt-8 shadow-md sm:w-full sm:max-w-md sm:mx-auto p-6">


        <div className="mt-4 mb-6">
            {selectionWarning ? 
            <div className='w-full text-center font-semibold text-2xl text-red-500 animate-bounce'>
                Select an ARE
            </div>
            :
            <div className='w-full text-center font-semibold text-2xl'>
                Select an ARE
            </div>
                }

            <div className='flex justify-center m-4'>
                <select  onChange={updateSelected} className='border border-gray-200/75 rounded-md min-h-12 px-4 py-2 w-1/4 flex'>
                    <option key='a' className='rounded-md' value={null}>{defaultText}</option>
                        {AreMapping.map( (i,index) =>{return (<option key={index} className='rounded-md' value={i}>{i}</option>)}
                        )}
                </select>
            </div>
     

            <div className='flex justify-center'>
                {props.loading ? 
                        <button
                            type="button"
                            className="px-4 py-2 border cursor-progress border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200"
                            disabled
                        >
                            Please Wait
                        </button>
                    
                    :
                        <button
                            type="button"
                            className="px-4 py-2 border cursor-pointer border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={handleSubmit}
                        >
                            Create Report
                        </button>

                    }
            </div>
        </div>
    </div>
  )
}
