import React, {useState, useEffect} from "react";
import Navbar from "components/navigation/navbar/Navbar";
import LoadingSpinner from "components/loading/LoadingSpinner";
import {axios_ksp_sales_amount_change, axios_open_fx_sales_doc_change_poll } from 'pages/fxDashboard/pages/salesDocChange/support_functions/axiosRequests'
import SuccessAlert from "components/alert/SuccessAlert";
import WarningAlert from "components/alert/WarningAlert";
import GenericFooter from "components/navigation/footer/GenericFooter";
import DatePicker from "react-datepicker";

export default function SalesDocChange(){

    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [endDate, setEndDate] = useState(new Date())
    const [startDate, setStartDate] = useState(new Date(new Date().setMonth( new Date().getMonth() - 1)));
    const[ fileUrl, setFileUrl] = useState()
    const[ processUUID, setProcessUUID] = useState()

    const startPolling = (uuid, pollCounter)=>{
        if (pollCounter === 14){
            setErrorMessage(true)
            setLoading(false)
            clearTimeout(startPolling)
            return 
        }


        axios_open_fx_sales_doc_change_poll(uuid).then(data =>{
                if (data.error){
                    setErrorMessage(true)
                    setLoading(false)
                    return
                }
                if (!data.data.processing){
                    setFileUrl(data.data.fileUrl)
                    clearTimeout(startPolling)
                    return
                }
                setTimeout(()=>startPolling(uuid,pollCounter+1), 5000);
            })
    }


    const handleSubmit = async () =>{
        let error = false
        setLoading(false)
        setSuccessMessage(false)
        setErrorMessage(false)

        if (error){
            return 
        }
        setLoading(true)

        axios_ksp_sales_amount_change(startDate,endDate).then( data =>{
            if (!data.error){                
                setProcessUUID(data.data.uuid)
            }else{
                setErrorMessage(true)
                setLoading(false)
            }
        })

    }




    useEffect(()=>{
        if(processUUID){
            startPolling(processUUID, 0)
        }
    },[processUUID])

    
    useEffect(()=>{
        if(fileUrl){
            window.open(fileUrl, "_blank")
            setLoading(false)
        }
    },[fileUrl])



    return (
        <div>

            <div>
                <Navbar activePage="/fx_dashboard"/>
            </div>


            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-full mt-16">
                    <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">KSP Sales Document Amount Change</h1>
                </div>
                
                {successMessage ?<div className="w-1/4 m-auto"><SuccessAlert title={'Export complete'} /></div>: <></>}

                {errorMessage ?<div className="w-1/4 m-auto"><WarningAlert title={'There was an error'} subtitle={'Contact Bernd or Kevin'} /></div>: <></>}

                <div className="mt-8 sm:mx-auto shadow sm:w-full sm:max-w-md">


                <div>

                    <p className="text-center font-semibold text-xl mt-4">Select search period</p>

                <div className="flex gap-4 justify-center">

                <div className='max-w-[70%]'>
                                <DatePicker
                                    className='border mt-6 text-center rounded-md'
                                    dateFormat="dd/MM/yyyy"
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                />
                                <div className="w-full text-center">
                                    <p>
                                        Date From <br/> (dd/mm/yyyy)
                                    </p>
                                </div>
                        </div>

                    <div className='max-w-[50%] px-4'>
                                <DatePicker
                                    className='border mt-6 text-center rounded-md'
                                    dateFormat="dd/MM/yyyy"
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                />
                                <div className="w-full text-center">
                                    <p>
                                        Date To <br/> (dd/mm/yyyy)
                                    </p>
                                </div>
                        </div>

                        </div>

                    </div>

                    <div className="mb-12 mt-8 flex justify-center m-auto">
                    {loading ? 
                                            <button
                            type="button"
                            className="px-4 py-2 border cursor-progress border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200"
                            disabled
                        >
                            Please Wait
                        </button>
                    
                    :
                        <button
                            type="button"
                            className="px-4 py-2 border cursor-pointer border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={handleSubmit}
                        >
                            Generate report
                        </button>

                    }
                    </div>
                </div>

            </div>


            <div>
                {loading ? 
                    <LoadingSpinner />

                    :
                    <></>
                }
            </div>

            <div>
                <GenericFooter />
            </div>
      
        </div>
        )
}