
import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';

const convertDate = (datepickerValue) =>{
    let year = datepickerValue.getFullYear()
    let month = datepickerValue.getMonth() + 1
    let day = datepickerValue.getDate()

    let formattedString = day + "/" + month + "/" + year 
    return (formattedString)
}

export const axios_open_invoice_scheduler = async  (selectedSapSystems, selectedSearchField, selectedCompCode, selectedClearedStatus, searchInput,startDate, endDate,selectedInvoiceStatus,selectedPaymentBlock ) =>{

    let data ={
        'searchInput' : searchInput, 
        'systemData': selectedSapSystems,
        'searchField' : selectedSearchField[0],
        'searchCompCode' : selectedCompCode, 
        'searchClearedStatus' : selectedClearedStatus[0], 
        'startDate' : convertDate(startDate),
        'endDate' : convertDate(endDate),
        'invoiceStatus' : selectedInvoiceStatus[0],
        'paymentBlock' : selectedPaymentBlock
    }


    try{
        const response = await axios({
            method: 'POST',
            url: sapDashboardAPIBaseuri + 'ap_dashboard/open_invoice/',
            headers: { 'content-type': 'application/json' },
            data : data,
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}


export const axios_open_invoice_companyCodes = async () =>{


    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'ap_dashboard/open_invoice/support/',
            headers: { 'content-type': 'application/json' },
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}

export const axios_open_invoice_poll =async (uuid) =>{
    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'ap_dashboard/open_invoice/poll/',
            headers: { 'content-type': 'application/json' },
            params: {processUUID : uuid},
            withCredentials: true,
        });
        
        if (response.status === 200 ){
            return {data: response.data, error : false}
            
        }else{
            return {data: response.data, error : false}
        }

    }catch(e){
        return {data: [], error : true}
    }
}

export const downloadJsonContents = async (urlPath) =>{
    const response = await axios({
        method: 'GET',
        url: urlPath,
        headers: { 'content-type': 'application/json' },
        responseType: 'application/json',
        transformResponse: (data) => JSON.parse(data),
    })
    if (response.status === 200){
        return response.data
    }
}
