
import {utils, writeFile} from 'xlsx';

export const ExportToExcel = (data, columnHeaders, fileName) =>{

    const col_names = [[...columnHeaders]]

    /* convert from workbook to array of arrays */
    const wb = utils.book_new()

    let ws = utils.WorkSheet = utils.json_to_sheet([]);

    utils.sheet_add_aoa(ws, col_names);

    if (data){
        utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true })
    }

    utils.book_append_sheet(wb, ws, 'Result')

    writeFile(wb, `${fileName}.xlsx`);

    return null
}
