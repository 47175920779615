import React, {useState} from 'react'
import Navbar from 'components/navigation/navbar/Navbar'
import { ArrowCircleUpIcon } from '@heroicons/react/outline'
import {read, utils} from 'xlsx';
import WarningAlert from 'components/alert/WarningAlert';
import { AxiosUploadFile } from './support_functions/axiosRequests';
import SuccessAlert from 'components/alert/SuccessAlert';
import {createTable, createInsertStatement} from './support_functions/sqlFunctions'

export default function AuditUpload() {
    const [loading, setLoading] = useState(false)
    const [showSubmitButton, setShowSubmitButton] = useState(false)
    const [uploadData, setUploadedData] = useState([])
    const [showWarning, setShowWarning] = useState(false)
    const [warningMessage, setWarningMessage] = useState('')
    const [workbookName, setWorkbookName] = useState('')
    const [showSuccess, setShowSuccess] = useState(false)
    const [columnHeaders, setColumnHeaders] = useState([])


    const handleSubmit = ()=>{
        setShowWarning(false)
        setShowSuccess(true)


        let tableCreation = createTable(workbookName,columnHeaders)
        let insertCreation = createInsertStatement(workbookName,columnHeaders, uploadData)

        setLoading(true)
        AxiosUploadFile(tableCreation, insertCreation).then(data =>{
            setShowSubmitButton(false)
            if(data.error){
                setWarningMessage("Error, contact Kevin")
                setShowWarning(true)
                setShowSuccess(false)
                setLoading(false)
                return
            }

            setLoading(false)
            setShowSuccess(true)
        })

    }



    const uploadExcelData = async (e) =>{
        setLoading(true)
        setShowWarning(false)
        const file = e.target.files[0]
        const data = await file.arrayBuffer();
        const wb = read(data, { type: 'array', cellFormula: false })
        const wsName = wb.SheetNames[0]
        let sheetData = utils.sheet_to_json(wb.Sheets[wsName])
        const columnsArray = utils.sheet_to_json(wb.Sheets[wsName], { header: 1 })[0];
        

        if (sheetData.length ===0){
            setWarningMessage("nothing found in 1st sheet of file")
            setShowWarning(true)
            return
       }

       Object.keys(columnsArray).forEach(i =>{
        if (/\S\s\S/.test(i)){
            setWarningMessage("Remove the space from '"+ i + "' column header")
            setShowWarning(true)
            return
        }

        

       })

       var regex = new RegExp('.xl');
       setWorkbookName(file.name.substring(0,file.name.search(regex)).replace(" ","_"))
       

       setColumnHeaders(columnsArray)
       setShowSubmitButton(true)
       setUploadedData(sheetData)
       setLoading(false)
    
    }
    

  return (
    <div>
        <Navbar activePage="/ac_operations"/>


        <div className="mb-12 mt-5 flex justify-center m-auto">

        <div className="min-h-full flex flex-col justify-center py-4 sm:px-6 lg:px-8 m-4 shadow-md w-1/3 rounded-md mx-auto">
                <div className="sm:mx-auto sm:w-full sm:max-w-md mt-4">
                    <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">SEA 5.0 Snowflake Upload</h1>
                    <div className='w-2/3 mx-auto mt-2'>
                        {showWarning ? <WarningAlert title={warningMessage} />:<></>}
                        {showSuccess ? <SuccessAlert title={"Upload complete"} /> : <></>}
                    </div>
                </div>



                <div className='flex flex-col gap-4'>

                    {!showSubmitButton ?
                            <div className="bg-grey-lighter mt-4 flex justify-center">
                                <label className="lg:w-64 md:w-24 flex flex-col items-center px-6 py-6 bg-white text-blue-500 rounded-lg shadow-lg tracking-wide uppercase border border-blue-500 cursor-pointer hover:bg-blue-300 hover:text-white">
                                <ArrowCircleUpIcon className='h-8 w-8' />
                                <span className="mt-2 text-base leading-normal text-center">Select a file to upload</span>
                                    <input onChange={uploadExcelData} type='file' className="hidden" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
                                </label>
                            </div>
                            :
                            <></>
                        }


                    {loading ? 
                        <button
                        type="button"
                        className="px-4 w-1/3 mx-auto mt-6 py-2 border cursor-progress border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200"
                        disabled
                        >
                            Please Wait
                        </button>
            
                    :<></>}

                    {showSubmitButton && loading === false ?
                        <button
                            type="button"
                            className="px-4 w-1/3 mx-auto mt-6 py-2 border cursor-pointer border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={handleSubmit}
                        >
                            Upload Data
                        </button>
                    :
                    <></>  }
                </div>

            </div>

        </div>

    </div>
  )
}
