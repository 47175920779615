import React, {useState, useEffect, useRef} from "react";
import Navbar from "components/navigation/navbar/Navbar";
import LoadingSpinner from "components/loading/LoadingSpinner";
import {axios_cash_allocation, axios_cash_allocation_support, downloadJsonContents } from 'pages/apDashboard/pages/cashAllocation/support_functions/axiosRequest'
import SuccessAlert from "components/alert/SuccessAlert";
import WarningAlert from "components/alert/WarningAlert";
import ReportTable from 'components/reportTable/reportTable'
import UserMenu from 'pages/apDashboard/pages/cashAllocation/components/userMenu'
import InputField from 'pages/apDashboard/pages/cashAllocation/components/inputField'
import GenerticFooter from 'components/navigation/footer/GenericFooter'



export default function CashAllocation(){

    const documentAmountRef = useRef('')
    const tolleranceAmountRef = useRef('')
    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [warningMessage, setWarningMessage] = useState('')
    const [showWarningMessage, setShowWarningMessage] = useState(false)
    const [showDataTable, setShowDataTable] = useState(false)
    const [tableData, setTableData] = useState([])

    const defaultColumnHeaders = ["SAP System",'Mandt','Company Code','ARE','Name','Vendor/Customer Number','Year','BSEG Doc Number','Reference Number','Currency','Document Amount','Bank Key','Bank Account Number','Doc Date','Key']
    const [searchInput, setSearchInput]= useState('')

    const [initCompCodeData, setInitCompCodeData]= useState([])
    const [documentCurrencies, setDocumentCurrencies] = useState([])
    const [selectedCurrency, setSelectedCurrency] = useState([])
  

    const [selectedSapSystems, setSelectedSapSystems] = useState([])
    const [selectedSearchField, setSelectedSearchField] = useState([])
    const [selectedCompCode, setSelectedCompCode] = useState([])
    const [selectedVendorCustomer, setSelectedVendorCustomer] = useState([])
    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date(new Date().setFullYear( new Date().getFullYear() - 7)));

    const[ fileUrl, setFileUrl] = useState()
    const[ jsonTableUrl, setJsonTableUrl] = useState()


    //export the data to excel 
    const exportDataToExcel = () =>{
        window.open(fileUrl, "_blank")
    }


    //handle the submit button
    const handleSubmit = async () =>{
        let error = false
        setLoading(false)
        setSuccessMessage(false)
        setShowDataTable(false)

        if (searchInput === '' && selectedSearchField[0] !== 'DOCUMENT_AMOUNT'){
            error = true
            setWarningMessage('You forgot to search for something')
            setShowWarningMessage(true)
        }else{
            setShowWarningMessage(false)
        }

        if (error === false){
            setLoading(true)

            try {
                axios_cash_allocation(selectedVendorCustomer, selectedSearchField,selectedSapSystems, selectedCompCode, startDate, endDate, searchInput, selectedCurrency, documentAmountRef.current.value, tolleranceAmountRef.current.value).then( data =>{
                    if (data.error){
                        setErrorMessage(true)
                        setLoading(false)
                        return
                    }
                    setFileUrl(data.data.fileUrl)
                    setJsonTableUrl(data.data.jsonUrl)    
                    return
                })
            }catch(e){
                console.log(e)
                setErrorMessage(true)
                setLoading(false)
            }
        }else{
            return null
        }
    }


    // wait until the table data is updated in use state before shwoing table
    useEffect(() =>{
        if (tableData.length !== 0){
            setLoading(false)        
            setShowDataTable(true)
            return
        }
    },[tableData])

    // when the jsonURl value is updated go get the data
    useEffect(()=>{
        if (jsonTableUrl){
            downloadJsonContents(jsonTableUrl).then(data =>{
                setTableData(data)
            })
        }
    },[jsonTableUrl])


    // retrieve the company code data
    useEffect(() =>{
        // this will serve as the company code/sap system company code 'source of truth'
        axios_cash_allocation_support(true).then(data =>{
            setInitCompCodeData(data.data.data)
        })
    },[])


    // retrieve the document_currenices
    useEffect(() =>{
        // this will serve as the company code/sap system company code 'source of truth'
        axios_cash_allocation_support(false).then(data =>{
            setDocumentCurrencies(data.data.data)
        })
    },[])







    return (
        <div>

            <div>
                <Navbar activePage="/ap_dashboard"/>
            </div>


            <div className="flex flex-col justify-center py-8 sm:px-6 lg:px-8 ">

                <div className="sm:mx-auto sm:w-full sm:max-w-md mt-4">
                    <h1 className="mt-4 text-center text-3xl font-extrabold text-gray-900">Cash Allocation Report</h1>
                </div>
                
                {successMessage ?<div className="w-1/4 m-auto"><SuccessAlert title={'Export complete'} /></div>: <></>}
                {errorMessage ?<div className="w-1/4 m-auto"><WarningAlert title={'There was an error'} subtitle={'Contact Bernd or Kevin'} /></div>: <></>}
                {showWarningMessage ?<div className="w-1/4 m-auto"><WarningAlert title={warningMessage} subtitle={''} /></div>: <></>}



            
                <div className="min-w-[33%] mt-8 shadow-md sm:w-full sm:max-w-md sm:mx-auto">

                <div className="mt-4 mb-6">
                    <UserMenu  setSelectedSapSystems={setSelectedSapSystems} selectedSearchField={selectedSearchField} setSelectedSearchField={setSelectedSearchField} setSelectedCompCode={setSelectedCompCode}
                        setSelectedVendorCustomer={setSelectedVendorCustomer} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} 
                        initCompCodeData={initCompCodeData} documentAmountRef={documentAmountRef} tolleranceAmountRef={tolleranceAmountRef} documentCurrencies={documentCurrencies}
                        setSelectedCurrency={setSelectedCurrency}
                        />
                </div>


                

                <div className="mt-4 mb-6 flex justify-center">
                    {selectedSearchField[0] === 'DOCUMENT_AMOUNT' ? 
                    <></>:
                    <InputField setSearchInput={setSearchInput} />
                    
                    }
                    
                </div>

                    <div className="mb-6 flex justify-center m-auto">
                    {loading ? 
                                            <button
                            type="button"
                            className="px-4 py-2 border cursor-progress border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200"
                            disabled
                        >
                            Please Wait
                        </button>
                    
                    :
                        <button
                            type="button"
                            className="px-4 py-2 border cursor-pointer border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={handleSubmit}
                        >
                            Search
                        </button>

                    }
                    </div>
                </div>

            </div>

            <div>
                {loading ? 
                    <LoadingSpinner />

                    :
                    <></>
                }
            </div>


            {showDataTable ? 
                <div className="w-2/3 m-auto mb-24">
                          <button
                                type="button"
                                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={exportDataToExcel}
                            > Export to excel</button>



                    <ReportTable data={tableData} columnHeaders={defaultColumnHeaders}  />
                </div>

                :
                <></>
            }


            <div>
                <GenerticFooter />
            </div>
      </div>
    )
}


