import axios from "axios";
import { sapDashboardAPIBaseuri } from "globals/variables/axiosVariables";

export async function AxiosUploadFile(tableCreation, insertCreation) {

    let postData = {
        tableCreation: tableCreation,
        insertStatement : insertCreation
    }

    try{
        const response = await axios({
            method: 'POST',
            url: sapDashboardAPIBaseuri + 'audit_dashboard/sea50_upload/',
            headers: { 'content-type': 'application/json' },
            data : postData,
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {error : false}
        }else{
            return {error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}

